import { theme } from 'antd';
const { defaultAlgorithm } = theme;

const colorPrimary = 'rgba(22, 23, 62, 1)';
const colorPrimaryHover = 'rgba(22, 23, 62, .6)';
const colorSecondary = 'rgba(72, 69, 223, 1)';
const colorSecondaryLight = 'rgba(72, 69, 223, .05)';
const colorSecondaryHover = 'rgba(72, 69, 223, .8)';
const colorInfo = 'rgba(110, 172, 217, 1)';
const colorSuccess = 'rgba(121, 238, 161, 1)';
const colorSuccessLight = 'rgba(121, 238, 161, .2)';
const colorWarning = 'rgba(255, 229, 143, 1)';
const colorWarningLight = 'rgba(255, 229, 143, .2)';
const colorError = 'rgba(250, 122, 104, 1)';
const colorErrorLight = 'rgba(250, 122, 104, .2)';

const textColor = '#4f4f4f';

const microdose = 'rgba(209, 173, 255, 1)';
const microdoseHover = 'rgba(209, 173, 255, .6)';

const venmo = 'rgba(161, 236, 187, 1)';
const venmoHover = 'rgba(161, 236, 187, .6)';

const borderRadius = {
	borderRadius: 2,
	borderRadiusSM: 2,
	borderRadiusLG: 2,
	borderRadiusXS: 2,
	borderRadiusOuter: 2,
	controlOutline: 'transparent',
};

const controlItemHover = {
	controlItemBgActiveHover: microdose,
	controlItemBgActive: microdose,
	controlItemBgHover: microdoseHover,
};

const fontWeight = {
	fontWeight: 400,
	fontWeightStrong: 500,
};

const fontFamily =
	'Graphik-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';

export const dashboardTheme = {
	algorithm: [defaultAlgorithm],
	token: {
		colorPrimary,
		colorSuccess,
		colorSuccessBg: colorSuccessLight,
		colorSuccessBorder: colorSuccess,
		colorWarning,
		colorWarningBg: colorWarningLight,
		colorWarningBorder: colorWarning,
		colorError,
		colorErrorBg: colorErrorLight,
		colorErrorBorder: colorError,
		colorInfo: colorSecondaryHover,
		colorInfoBg: colorSecondaryLight,
		colorInfoBorder: colorSecondary,
		colorTextBase: textColor,
		colorTextSecondary: textColor,
		colorBgSpotlight: colorPrimary,
		colorBgLayout: '#f8f7f6',
		fontFamily,
		...fontWeight,
	},
	components: {
		Alert: {
			colorText: textColor,
			...borderRadius,
		},
		Anchor: { fontFamily, ...fontWeight },
		Avatar: {
			colorTextPlaceholder: colorPrimary,
		},
		Button: {
			colorPrimary: colorSecondary,
			colorPrimaryHover: colorSecondaryHover,
			colorLink: colorPrimary,
			colorLinkHover: colorPrimaryHover,
			colorBgTextActive: '#fff',
			colorBgTextHover: '#fff',
			...borderRadius,
		},
		Card: { ...borderRadius, colorPrimary: textColor },
		Cascader: {
			...borderRadius,
			...controlItemHover,
		},
		Checkbox: { colorPrimary },
		DatePicker: {
			...borderRadius,
			...controlItemHover,
			colorIconHover: microdoseHover,
			colorLinkActive: microdoseHover,
			colorIcon: microdoseHover,
		},
		Dropdown: {
			...borderRadius,
			...controlItemHover,
		},
		Header: {
			padding: 0,
			paddingSM: 0,
		},
		Input: borderRadius,
		InputNumber: borderRadius,
		Menu: {
			colorPrimary,
			itemBg: colorPrimary,
			itemHoverBg: colorSecondary,
			groupTitleColor: '#a5a5a5',
			colorText: 'hsla(0,0%,100%,.65)',
			colorBgContainer: colorPrimary,
			itemHoverColor: 'hsla(0,0%,100%,.65)',
			itemColor: '#a5a5a5',
			controlItemBgActiveHover: colorSecondary,
			borderRadius: 0,
			radiusItem: 0,
			itemActiveBg: colorSecondary,
			itemSelectedBg: colorSecondary,
			horizontalItemSelectedBg: colorSecondary,
			margin: 4,
			marginXXS: 4,
			itemMarginInline: 0,
		},
		Message: {
			colorInfo,
			colorSuccess,
			colorError,
			colorWarning,
		},
		Modal: {
			...borderRadius,
		},
		Pagination: {
			...borderRadius,
		},
		RangePicker: {
			...borderRadius,
			...controlItemHover,
			colorIconHover: microdoseHover,
			colorIcon: microdose,
		},
		Radio: {
			...borderRadius,
		},
		Result: {
			colorInfo,
			colorSuccess,
			colorWarning,
			colorError,
		},
		Select: {
			...borderRadius,
			...controlItemHover,
			colorFillSecondary: '#f5f5f5',
		},
		Sider: {
			colorPrimaryBg: colorPrimary,
		},
		Table: {
			...borderRadius,
			colorLink: colorPrimary,
			colorLinkHover: colorPrimaryHover,
			colorText: '#4f4f4f',
			colorFillAlter: '#f0eeed',
			colorFillSecondary: '#f5f5f5',
			controlItemBgActiveHover: venmoHover,
			controlItemBgActive: venmo,
			controlItemBgHover: microdoseHover,
		},
		Tag: {
			colorText: colorPrimary,
		},
		TreeSelect: {
			...borderRadius,
			...controlItemHover,
			colorFillSecondary: '#f5f5f5',
		},
		Typography: {
			colorLink: colorInfo,
			colorText: textColor,
			colorTextHeading: textColor,
			colorLinkHover: colorSecondary,
			lineHeightHeading2: 1.0,
		},
	},
};
