export const ADDRESS_SEARCH_OPTIONS = {
	types: ['address'],
};
export const STOREFRONT = 'STOREFRONT';

// street_number --> street_number
// route --> street name
// locality --> city
// sublocality --> alternative to city eg. Brooklyn
// administrative_area_level_3 --> another alternative to city eg.
// administrative_area_level_1 --> state
// country	--> country
// postal_code --> zip_code
export const ADDRESS_FIELDS = [
	'street_number',
	'route',
	'locality',
	'sublocality',
	'administrative_area_level_3',
	'administrative_area_level_1',
	'country',
	'postal_code',
];

export const REQUIRED_ADDRESS_FIELDS = [
	'address',
	'address_latitude',
	'address_longitude',
	'city',
	'state',
	'zip_code',
	'country',
];

export const REQUIRED_COMPANY_FIELDS = [
	'storefront_name',
	...REQUIRED_ADDRESS_FIELDS,
];

export const REQUIRED_MERCHANT_FIELDS = [
	'merchant_store_name',
	'merchant_store_phone_number',
	'merchant_store_hero_image_url',
	'merchant_store_logo_image_url',
	'merchant_store_prep_time',
];
export const MERCHANT_STORE_PREP_TIME = 'merchant_store_prep_time';

export const ORDER_ADJUSTMENT_TYPES = {
	refund: {
		label: 'Refund',
		color: 'success',
	},
	charge: {
		label: 'Upcharge',
		color: 'warning',
	},
};

export const STOREFRONT_ACCOUNT_MENU = {
	MY_ACCOUNT: {
		label: 'My Account',
		route: '/store/account',
		disabled: false,
	},
	RESET_PASSWORD: {
		label: 'Password',
		route: '/store/account/change-password',
		disabled: false,
	},
	ORDER_HISTORY: {
		label: 'Order History',
		route: '/store/account/order-history',
		disabled: false,
	},
	// Leave commented out until STOR-273
	// hiding cards until they can be used
	// PAYMENT_OPTIONS: {
	// 	label: 'Payment Options',
	// 	route: '/store/account/payments',
	// 	disabled: false,
	// },
};

export const STOREFRONT_HEADER_CONTAINER = 'storefront__header-container';
export const STOREFRONT_HEADER = 'storefront__header';
export const DEFAULT_AVAILABLE_RESPONSE = { available: false, message: null };
export const CONSUMER_IS_CHARGED = 'CONSUMER_IS_CHARGED';
export const RESTAURANT_IS_CHARGED = 'RESTAURANT_IS_CHARGED';
export const CANCELLED_BY_CONSUMER = 'Bulk cancelled by Consumer';
export const CANCELLED_BY_RESTAURANT = 'Bulk cancelled by Restaurant';
export const KILOMETERS = 'KILOMETERS';
export const MILES = 'MILES';

export const PAYMENT_SETUP = 'PAYMENT_SETUP';
export const DELIVERY_SETUP = 'DELIVERY_SETUP';
export const PROFILE_SETUP = 'PROFILE_SETUP';
export const ADDITIONAL_FIELDS_SETUP = 'ADDITIONAL_FIELDS_SETUP';
export const GOOGLE_SETUP = 'GOOGLE_SETUP'; // plugins
export const WORDPRESS_SETUP = 'WORDPRESS_SETUP'; // plugins
export const STOREFRONT_SETTINGS = 'STOREFRONT_SETTINGS';
export const PLUGINS = 'PLUGINS';
export const STOREFRONT_URLS = 'STOREFRONT_URLS';
