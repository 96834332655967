export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const STORE_DATA = 'STORE_DATA';
export const BULK_STORE_DATA = 'BULK_STORE_DATA';
export const EDIT_CART_OPTION = 'EDIT_CART_OPTION';
export const ADD_TO_CART = 'ADD_TO_CART';
export const EDIT_CART_ITEM = 'EDIT_CART_ITEM';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART';
export const SET_CHECKOUT_SESSION_DATA = 'SET_CHECKOUT_SESSION_DATA';
export const SET_CHECKOUT_SESSION_LOADING = 'SET_CHECKOUT_SESSION_LOADING';
export const SET_CHECKOUT_SESSION_ERROR = 'SET_CHECKOUT_SESSION_ERROR';
export const CONFIG = 'CONFIG';
export const MENUS = 'MENUS';
export const RESET = 'RESET';
export const END_OF_DAY = '23:59';
export const START_OF_DAY = '00:00';
export const MONDAY_ORDINAL = 1;
export const SUNDAY_ORDINAL = 7;

export const DAY_TO_WEEKDAY = {
	Mon: 'monday',
	Tue: 'tuesday',
	Wed: 'wednesday',
	Thu: 'thursday',
	Fri: 'friday',
	Sat: 'saturday',
	Sun: 'sunday',
};

export const ORDER_TYPE = {
	ASAP: 'ASAP',
	SCHEDULED: 'SCHEDULED',
};

export const MINIMUM_CHECKOUT_PRICE = 0.5;
export const MAXIMUM_CHECKOUT_PRICE = 500000.0;
export const MAXIMUM_LINE_COUNT = 99;
export const NO_ERROR_STATE = {
	hasError: false,
	message: null,
};

export const SUBSCRIPTION_ERROR = 'pusher:subscription_error';
export const SUBSCRIPTION_SUCCEEDED = 'pusher:subscription_succeeded';
export const ORDER_SUBSCRIBED = 'ORDER_SUBSCRIBED';
export const ORDER_UPDATED = 'ORDER_UPDATED';

export const COMPLETED = 1;
export const IN_PROGRESS = 2;
export const ACTIVE = 3;
export const CANCELLED = 4;
export const SCHEDULED = 5;
export const INACTIVE = 6;

export const ORDER_STEP = {
	[ACTIVE]: 0,
	[SCHEDULED]: 0,
	[INACTIVE]: 0,
	[IN_PROGRESS]: 1,
	[CANCELLED]: 2,
	[COMPLETED]: 2,
};

export const ORDER_TYPE_RADIO_GROUP_OPTIONS = [
	{ label: 'As soon as possible', value: ORDER_TYPE.ASAP },
	{ label: 'For Later', value: ORDER_TYPE.SCHEDULED },
];

export const DEFAULT_NEW_CHECKOUT_FIELD_STATE = {
	label: null,
	description: null,
	required: false,
	type: 'string',
};

export const SAVED_CART = 'savedCart';
export const EXPIRY_DATE = 'expiryDate';
export const ITEMS = 'items';
export const ITEM = 'item';
export const MODIFIER_GROUPS = 'modifierGroups';
export const MODIFIERS = 'modifiers';
export const OTHER = 'OTHER';
export const PERCENTAGE = 'PERCENTAGE';

export const FULFILLMENT_TYPES = {
	DELIVERY: 'DELIVERY',
	PICKUP: 'PICKUP',
	KIOSK: 'KIOSK',
};

export const kioskFulfillmentType = 'kioskFulfillmentType';

export const LEAVE_AT_DOOR = 'Leave it at my door';
export const HAND_TO_ME = 'Hand it to me';

export const CUBOH_PREFERRED_COUNTRY_CODES = [
	'CA',
	'US',
	'MX',
	'AU',
	'GB',
	'PR',
	'VI',
];

export const FEE_LINE_ITEMS = [
	'Cuboh Service Fee',
	'Delivery Service Fee',
	'Tax',
];

export const TAXES_AND_FEES = 'Taxes & Fees';
export const GRAND_TOTAL = 'Grand Total';

// stripe payment intent status
export const PAYMENT_INTENT_STATUS = {
	SUCCEEDED: 'succeeded',
	REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
	PROCESSING: 'processing',
	REQUIRES_SOURCE: 'requires_source',
	CARD_ERROR: 'card_error',
	VALIDATION_ERROR: 'validation_error',
};

export const TERMINAL_PUSHER_EVENTS = {
	TERMINAL_UPDATED: 'TERMINAL_UPDATED',
};
export const TERMINAL_PUSHER_ACTIONS = {
	PAYMENT_SUCCESS: 'READER_ACTION_SUCCESS',
	PAYMENT_FAILURE: 'READER_ACTION_FAILED',
	INTENT_SENT: 'PAYMENT_INTENT_SENT_TO_TERMINAL',
	PAYMENT_CANCELLED: 'CANCEL_TERMINAL_PROCESS',
};
