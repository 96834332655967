import {
	SET_IS_LOADING,
	SET_ERROR,
	SET_DATA,
	UPDATE_COMPANY,
	UPDATE_MERCHANT,
	UPDATE_PARENT_COMPANY,
	SET_SELECTED_COMPANY,
	SET_SELECTED_MERCHANT,
	SET_SELECTED_TIMEZONE,
	SET_PROXY_USER,
	SET_ORDER_DISPLAY_TOKEN,
	SET_FEATURE_FLAGS,
	SET_USER_META,
	SET_SOURCE,
} from 'constants/auth.constants';

export const DEFAULT_STATE = {
	isLoading: false,
	hasError: null,
	user: null,
	orderDisplayToken: null,
	companies: {},
	merchants: {},
	parentCompany: {},
	selectedMerchantID: null,
	selectedCompanyID: null,
	selectedCompanies: null,
	selectedTimezone: 'US/Eastern',
	proxyUserID: null,
	featureFlags: null,
	source: null,
};

export const reducer = (state, action) => {
	switch (action.type) {
		case SET_IS_LOADING:
			return { ...state, isLoading: action.isLoading };
		case SET_ERROR:
			return { ...state, hasError: action.hasError, message: action.message };
		case SET_DATA:
			return { ...state, ...action.data };
		case SET_ORDER_DISPLAY_TOKEN:
			return { ...state, orderDisplayToken: action.orderDisplayToken };
		case UPDATE_COMPANY:
			return {
				...state,
				companies: {
					...state.companies,
					[action.id]: {
						...state.companies[action.id],
						...action.payload,
					},
				},
			};
		case UPDATE_MERCHANT:
			return {
				...state,
				merchants: {
					...state.merchants,
					[action.id]: {
						...state.merchants[action.id],
						...action.payload,
					},
				},
			};
		case UPDATE_PARENT_COMPANY:
			return {
				...state,
				parentCompany: {
					...state.parentCompany,
					...action.parentCompany,
				},
			};
		case SET_SELECTED_COMPANY:
			return {
				...state,
				selectedCompanyID: action.companyID,
			};
		case SET_SELECTED_MERCHANT:
			return {
				...state,
				selectedMerchantID: action.merchantID,
			};
		case SET_SELECTED_TIMEZONE:
			return {
				...state,
				selectedTimezone: action.timezone,
			};
		case SET_PROXY_USER:
			return {
				...state,
				proxyUserID: action.proxyUserID,
			};
		case SET_USER_META:
			return {
				...state,
				user: {
					...state.user,
					userDetails: {
						...state.userDetails,
						userMeta: {
							...action.userMeta,
						},
					},
				},
			};
		case SET_FEATURE_FLAGS:
			return {
				...state,
				featureFlags: action.featureFlags,
			};
		case SET_SOURCE:
			return {
				...state,
				source: action.source,
			};
		default:
			return { ...state };
	}
};
