import { useEffect, useState, useCallback, useMemo } from 'react';

import { createAssetAxiosInstance } from 'createAxiosInstance';

import { ALL_LOCATIONS } from 'constants/auth.constants';
import { CANADA } from 'constants/constants';

import { valueIsPresent } from 'utils/helpers';
import { fetchTerminalReaders } from 'utils/kiosk.helper';
import { showMultiSelect } from 'utils/select.helper';

import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'hooks/useRouter';

export default function useProvideStorefrontAndKiosk() {
	const { selectedCompany, proxyUserID, companies, merchants } = useAuth();
	const { location, query } = useRouter();
	const [isLoading, setIsLoading] = useState(false);
	const [
		storefrontAndKioskValidationData,
		setStorefrontAndKioskValidationData,
	] = useState({});
	const [companiesToQuery, setCompaniesToQuery] = useState([]);

	const selectedCompanyHasValidStorefront = useCallback(
		(storefrontConfig) => {
			for (const storefrontConfigMerchant of storefrontConfig?.merchants ??
				[]) {
				if (
					!storefrontConfigMerchant.disabled &&
					storefrontConfigMerchant.menus.length > 0
				) {
					if (
						merchants[storefrontConfigMerchant.merchantId]
							?.merchant_store_active
					) {
						return true;
					}
				}
			}
			return false;
		},
		[merchants],
	);

	// If we're on a multi-select page, try to get the companies from the router
	// Otherwise use the selected company id in state
	useEffect(() => {
		const isOnMultiSelectPage = showMultiSelect(location);
		const routerCompanyIDs = query?.multiCompany;

		if (isOnMultiSelectPage && routerCompanyIDs === ALL_LOCATIONS) {
			setCompaniesToQuery(Object.values(companies ?? {}));
		} else if (isOnMultiSelectPage && routerCompanyIDs) {
			setCompaniesToQuery(
				routerCompanyIDs
					.split(',')
					?.filter(
						(companyID) =>
							valueIsPresent(companies[companyID]) &&
							parseInt(companyID) === parseInt(companies[companyID].id),
					)
					?.map((companyID) => companies[companyID]),
			);
		} else if (selectedCompany) {
			setCompaniesToQuery([selectedCompany]);
		}
	}, [location, companies, selectedCompany, query?.multiCompany]);

	const fetchStorefrontConfig = async (storefrontPath) => {
		try {
			const axios = await createAssetAxiosInstance();
			const response = await axios.get(
				`/company-storefronts/${storefrontPath}.json?v=${Date.now()}`,
			);
			return response.data;
		} catch (error) {
			console.error('Error loading storefront config', error);
			return {};
		}
	};

	const fetchKioskConfig = async (companyID) => {
		try {
			const terminalReaders = await fetchTerminalReaders(companyID);
			return {
				terminalReaders,
			};
		} catch (error) {
			console.error('Error loading kiosk config', error);
			return {};
		}
	};

	useEffect(() => {
		(async function () {
			if (!companiesToQuery.length || proxyUserID) {
				return;
			}
			setIsLoading(true);
			await Promise.all(
				companiesToQuery
					?.filter(
						(company) =>
							!Object.keys(storefrontAndKioskValidationData).includes(
								company.id,
							),
					) // we don't want to re-query if we already have the results
					?.map(async (company) => {
						// for storefronts they must have active store merchants + live menus
						// OR be in canada
						const storefrontConfig = await fetchStorefrontConfig(
							company.storefrontPath,
						);
						const validStorefront =
							selectedCompanyHasValidStorefront(storefrontConfig);
						const inCanada = (company.country ?? '').toLowerCase() === CANADA;

						// for kiosks they must have active store merchants + live menus
						// AND have terminal readers set up
						const kioskConfig = await fetchKioskConfig(company.id);
						const validKiosk =
							validStorefront && kioskConfig?.terminalReaders?.length > 0;

						const updatedValidationData = {
							...storefrontAndKioskValidationData,
							[company.id]: {
								validStorefront: validStorefront || inCanada,
								validKiosk,
							},
						};
						setStorefrontAndKioskValidationData(updatedValidationData);
					}),
			);

			setIsLoading(false);
		})();
		// we don't want this to trigger on "storefrontAndKioskValidationData" changing because it would cause an infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companiesToQuery, proxyUserID, selectedCompanyHasValidStorefront]);

	const canSeeStorefront = useMemo(() => {
		if (proxyUserID) {
			return true;
		}
		return companiesToQuery?.some(
			(company) =>
				storefrontAndKioskValidationData[company.id]?.validStorefront,
		);
	}, [proxyUserID, companiesToQuery, storefrontAndKioskValidationData]);

	const canSeeKiosk = useMemo(() => {
		if (proxyUserID) {
			return true;
		}
		return companiesToQuery?.some(
			(company) => storefrontAndKioskValidationData[company.id]?.validKiosk,
		);
	}, [proxyUserID, companiesToQuery, storefrontAndKioskValidationData]);

	return {
		isLoading,
		canSeeStorefront,
		canSeeKiosk,
	};
}
