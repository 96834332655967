import { useState } from 'react';

import { useAuth } from 'hooks/useAuth';

export default function useProvideResponsiveLayout() {
	const { user } = useAuth();
	const userPrefersSidebarCollapsed =
		user?.userDetails?.userMeta?.sidebarCollapsed;

	const [collapsed, setCollapsed] = useState(
		userPrefersSidebarCollapsed ?? false,
	);
	const [broke, setBroke] = useState(false);

	const toggle = () => {
		const currentStateOfCollapsed = collapsed;
		setCollapsed((prev) => !prev);
		return !currentStateOfCollapsed;
	};

	const onBroken = (broke) => {
		if (broke) {
			setCollapsed(true);
		} else if (!broke && !userPrefersSidebarCollapsed) {
			setCollapsed(false);
		}
		setBroke(broke);
	};

	return { broke, collapsed, toggle, onBroken };
}
