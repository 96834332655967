import React, { useCallback } from 'react';

import { Result, Button } from 'antd';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import { ENVIRONMENT } from 'constants/config.constants';
import { LOG_LEVEL } from 'constants/constants';

import { logToCore } from 'utils/helpers';

import { useRouter } from 'hooks/useRouter';

LoggingErrorBoundary.propTypes = {
	children: PropTypes.node,
	FallbackComponent: PropTypes.node,
};

LoggingErrorBoundary.DefaultFallback = function DefaultFallback() {
	const { pathname } = useRouter();
	return (
		<div className="full-height">
			<Result
				status="500"
				subTitle="An unexpected error has occurred"
				extra={
					<Button type="primary" href={pathname}>
						Refresh
					</Button>
				}
			/>
		</div>
	);
};

LoggingErrorBoundary.DefaultFallback.propTypes = {
	error: PropTypes.object,
};

export default function LoggingErrorBoundary({
	children,
	FallbackComponent = LoggingErrorBoundary.DefaultFallback,
}) {
	const { pathname } = useRouter();

	const onError = useCallback(
		async (error, info) => {
			if (process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.production) return;
			try {
				await logToCore({
					level: LOG_LEVEL.ERROR,
					message: `[DASHBOARD] url=${pathname}, browserInfo=${navigator.userAgent}, error=${error}, stacktrace=${info.componentStack}`,
					alert: true,
				});
			} catch (e) {
				console.error(e);
			}
		},
		[pathname],
	);

	return (
		<ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
			{children}
		</ErrorBoundary>
	);
}
