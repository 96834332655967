import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { ALL_LOCATIONS } from 'constants/auth.constants';
import {
	SOURCE_CHOWNOW_ALLOWED_PATHS,
	SOURCE_CHOWNOW,
} from 'constants/chownow.constants';
import { COMPANY_UPDATED } from 'constants/notification.constants';

import {
	setIsLoading,
	setError,
	setData,
	setFeatureFlags,
	updateCompany,
	updateMerchant,
	updateParentCompany,
	setSelectedCompanyID,
	setSelectedMerchantID,
	setSelectedTimezone,
	setProxyUserID,
	setUserMeta,
	setSource,
} from 'actions/auth.actions';

import { reducer, DEFAULT_STATE } from 'reducers/auth.reducer';

import * as authHelper from 'utils/auth.helper';
import { updateUserMeta } from 'utils/helpers';
import { fetchStorefrontLocationsData as fetchStorefrontLocationsDataByCompany } from 'utils/menuCheckout.helper';
import { fetchStorefrontLocationsData as fetchStorefrontLocationsDataByParent } from 'utils/storefront.helper';

import useAuthToken from 'hooks/useAuthToken';
import useBindRoute from 'hooks/useBindRoute';
import { useNotify } from 'hooks/useNotify';
import { useRouter } from 'hooks/useRouter';

// Provider hook that creates auth object and handles state
export default function useProvideAuth() {
	const { setChannelNames, pusher, channels } = useNotify();
	const { query, pathname } = useRouter();
	const router = useRouter();
	const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
	const { user, companies, merchants, selectedCompanyID, selectedMerchantID } =
		state;
	const [hasStorefront, setHasStorefront] = useState();

	// if the source is from chownow, we only want them to access specific pages
	// if they try to veer away from those pages, redirect them to the menu management page
	useEffect(() => {
		if (
			user &&
			state.source === SOURCE_CHOWNOW &&
			!SOURCE_CHOWNOW_ALLOWED_PATHS.includes(pathname)
		) {
			router.push('/menu/management');
		}
	}, [router, pathname, state.source, user]);

	useEffect(() => {
		if (
			!state.user ||
			(!state.user && !state) ||
			(!state.parentCompany.id && !state.user.companyID)
		) {
			return;
		}

		const checkCompanyHasStorefront = async ({
			isParentCompanyUser = false,
			parentCompanyID = null,
			companyID = null,
		}) => {
			try {
				let data = null;
				if (parentCompanyID && isParentCompanyUser) {
					data = await fetchStorefrontLocationsDataByParent(parentCompanyID);
				} else {
					data = await fetchStorefrontLocationsDataByCompany(companyID);
				}
				setHasStorefront(data.storefronts.length > 0);
			} catch (error) {
				// If toggling multiple times returns a 500 so we don't want to show upsell
				if (error.response && error.response.status === 404)
					setHasStorefront(false);
			}
		};
		checkCompanyHasStorefront({
			isParentCompanyUser: state.user.isParentCompanyUser,
			parentCompanyID: state.parentCompany.id,
			companyID: state.user.companyID,
		});
	}, [state]);

	const getUserData = useCallback(async () => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(setIsLoading({ isLoading: true }));
			const source = authHelper.getSource();
			dispatch(setSource({ source }));
			const [newUserData, featureFlags] = await Promise.all([
				await authHelper.fetchUserData(),
				await authHelper.fetchFeatureFlags(),
			]);
			dispatch(setData({ data: newUserData }));
			dispatch(setFeatureFlags({ featureFlags }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		} finally {
			dispatch(setIsLoading({ isLoading: false }));
		}
	}, []);

	const onLogout = useCallback(() => {
		dispatch(setData({ data: DEFAULT_STATE }));
		if (pusher) pusher.disconnect();
	}, [pusher]);

	const { getAccessToken, login, logout } = useAuthToken({
		refreshOnPageLoad: false,
		keepTokenAlive: true,
		onLogin: getUserData,
		onLogout: onLogout,
	});

	useEffect(() => {
		let isSubscribed = true;
		const missingAccessToken = !getAccessToken() || !window;

		(async function () {
			if (isSubscribed) {
				try {
					if (missingAccessToken) {
						return;
					}
					await getUserData();
				} catch (error) {
					console.error(error);
				}
			}
		})();

		return () => {
			isSubscribed = false;
		};
	}, [getUserData, getAccessToken]);

	// Identify user on analytics applications
	useEffect(() => {
		if (!user?.id) return;

		setChannelNames(
			Object.values(companies)?.map(
				(company) => `${process.env.REACT_APP_WS_STAGE}-company-${company.id}`,
			),
		);

		if (user.id) {
			if (user.tokenClaims?.proxy_user) {
				dispatch(setProxyUserID({ proxyUserID: user.tokenClaims?.proxy_user }));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (!channels?.length > 0) {
			return;
		}

		const setupPusherEvents = async () => {
			channels.map((channel) => {
				channel.bind(COMPANY_UPDATED, async (company) => {
					dispatch(
						updateCompany({
							id: company.id,
							payload: company,
						}),
					);
				});
			});
		};

		setupPusherEvents();
	}, [channels]);

	const selectCompany = useCallback((companyID) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(setSelectedCompanyID({ companyID }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	}, []);

	const selectMerchant = useCallback(
		(merchantID) => {
			try {
				dispatch(setError({ hasError: false, message: '' }));
				dispatch(setSelectedMerchantID({ merchantID: merchantID }));
				if (merchantID) {
					dispatch(
						setSelectedCompanyID({
							companyID: merchants[merchantID].companyID,
						}),
					);
				}
			} catch (e) {
				dispatch(setError({ hasError: true, message: e.message }));
			}
		},
		[merchants],
	);

	// For updating based on route
	useBindRoute(
		'companyID',
		useCallback(
			(companyID) => {
				if (companyID === ALL_LOCATIONS) {
					selectCompany(companyID);
					return;
				}

				if (companyID in companies) {
					selectCompany(companyID);
				}
			},
			[companies, selectCompany],
		),
	);
	useBindRoute(
		'merchantID',
		useCallback(
			(merchantID) => {
				// Manually set null as parseInt(null) is NaN
				const currentMerchantID = query.merchantID
					? query.merchantID
					: merchantID;
				if (currentMerchantID in merchants || currentMerchantID === null) {
					selectMerchant(currentMerchantID === null ? null : currentMerchantID);
				}
			},
			[merchants, query.merchantID, selectMerchant],
		),
	);

	/**
	 * Check whether the user has permission to perform an action
	 * @param permission: String permission constant from auth.constants
	 *
	 * @returns if user has permission, default to false for no user in state
	 */
	const hasPermission = useCallback(
		(permission) => {
			if (!user) {
				return false;
			}

			return user.permissions.includes(permission);
		},
		[user],
	);

	const selectTimezone = ({ timezone }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(setSelectedTimezone({ timezone }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const updateCompanyDetails = ({ company }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(updateCompany({ id: company.id, payload: company }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const updateMerchantDetails = ({ merchant }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(updateMerchant({ id: merchant.id, payload: merchant }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const updateCompanyMeta = ({ companyID, company_meta }) => {
		dispatch(
			updateCompany({
				id: companyID,
				payload: { company_meta },
			}),
		);
	};

	const updateMerchantMeta = ({ merchantID, merchant_meta }) => {
		dispatch(
			updateMerchant({
				id: merchantID,
				payload: { merchant_meta },
			}),
		);
	};

	const bulkUpdateMerchantDetails = ({ updatedMerchants }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			const merchants = { ...state.merchants };

			updatedMerchants.forEach((updatedMerchant) => {
				merchants[updatedMerchant.id] = {
					...merchants[updatedMerchant.id],
					...updatedMerchant,
				};
			});

			dispatch(
				setData({
					data: { merchants: merchants },
				}),
			);
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const updateParentCompanyDetails = ({ parentCompany }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			dispatch(updateParentCompany({ parentCompany }));
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const updateUserMetaForUser = async ({ userMeta, failureMessage }) => {
		try {
			dispatch(setError({ hasError: false, message: '' }));
			const newUserMeta = await updateUserMeta({
				userMeta,
				failureMessage,
			});
			dispatch(
				setUserMeta({
					userMeta: newUserMeta,
				}),
			);
		} catch (e) {
			dispatch(setError({ hasError: true, message: e.message }));
		}
	};

	const selectedCompany = useMemo(
		() => companies[selectedCompanyID],
		[companies, selectedCompanyID],
	);

	const selectedMerchant = useMemo(() => {
		return merchants[selectedMerchantID];
	}, [merchants, selectedMerchantID]);

	const flags = useMemo(() => user?.flags || {}, [user]);

	const notificationID = useMemo(() => {
		if (user) {
			return uuidv4();
		}
	}, [user]);

	const selectedReportMerchants = useMemo(() => {
		const allMerchants = Object.values(merchants);
		if (selectedCompanyID === ALL_LOCATIONS) {
			return allMerchants;
		}

		if (!selectedMerchantID) {
			return allMerchants.filter(
				(merchant) => merchant.companyID === selectedCompanyID,
			);
		}

		return [merchants[selectedMerchantID]];
	}, [merchants, selectedMerchantID, selectedCompanyID]);

	const selectedCompanyMerchants = useMemo(() => {
		const allMerchants = Object.values(merchants);
		if (selectedCompanyID === ALL_LOCATIONS) {
			return allMerchants;
		}
		return allMerchants.filter(
			(merchant) => merchant.companyID === selectedCompanyID,
		);
	}, [merchants, selectedCompanyID]);

	useEffect(() => {
		// If the selected company has only one merchant, select it
		if (selectedCompanyMerchants.length === 1) {
			selectMerchant(selectedCompanyMerchants[0].id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCompanyMerchants]);

	const isSourceChowNow = state.source === SOURCE_CHOWNOW;

	return {
		...state,
		getUserData,
		selectCompany,
		selectMerchant,
		selectTimezone,
		updateCompanyDetails,
		updateMerchantDetails,
		bulkUpdateMerchantDetails,
		updateParentCompanyDetails,
		hasPermission,
		login,
		logout,
		selectedCompany,
		selectedMerchant,
		flags,
		featureFlags: state?.featureFlags,
		selectedCompanyMerchants,
		userMeta: state?.userDetails?.userMeta,
		selectedReportMerchants,
		isGuest: false,
		notificationID,
		updateUserMetaForUser,
		hasStorefront,
		updateCompanyMeta,
		updateMerchantMeta,
		isSourceChowNow,
	};
}
