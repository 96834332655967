import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';

import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'hooks/useRouter';

import logo from 'assets/icons/chownow-icons/favicon.png';
import cubohLogo from 'assets/icons/favicon.ico';

export default function Helm() {
	const { isSourceChowNow } = useAuth();
	const { pathname } = useRouter();

	const chownowTitle = useMemo(() => {
		if (!isSourceChowNow) return;
		if (pathname.toLocaleLowerCase().includes('menu')) {
			return 'Menu | ChowNow Dashboard';
		} else if (
			pathname.toLocaleLowerCase().includes('reporting') ||
			pathname.toLocaleLowerCase().includes('analytics')
		) {
			return 'Reporting & Analytics | ChowNow Dashboard';
		} else {
			return 'ChowNow Dashboard';
		}
	}, [isSourceChowNow, pathname]);

	if (!isSourceChowNow) {
		return (
			<Helmet>
				<title>Cuboh - Online Ordering Manager</title>
				<link rel="icon" type="image/png" href={cubohLogo} />
			</Helmet>
		);
	}
	return (
		<Helmet>
			<title>{chownowTitle}</title>
			<link rel="icon" type="image/png" href={logo} />
		</Helmet>
	);
}
