import React from 'react';

import { Typography } from 'antd';

import { ALL_LOCATIONS } from 'constants/auth.constants';

const { Text } = Typography;

export function showMultiSelect(location) {
	const multiSelectPages = [
		'/analytics',
		'/reports/order',
		'/reports/storefront-order',
		'/reports/item',
		'/reports/modifier',
		'/reports/pos-error',
		'/reports/control',
		'/reports/adjustment',
		'/reports/cancellations',
	];
	if (multiSelectPages.includes(location.pathname)) return true;
	return false;
}

export function hideMerchantSelected(location) {
	const hideMerchantSelected = [
		'/',
		'/manage/payments',
		'/manage/storefronts',
		'/manage/orders-display',
		'/menu/mapping',
		'/profile/invoices',
	];

	if (hideMerchantSelected.includes(location.pathname)) return true;
}

export function treeData(options) {
	return options.map((option) => ({
		key: option.value,
		value: option.value,
		title: option.label,
		readableTitle: option.label,
		fullTitle: option.label,
		'data-cy': `parent-select-${option.label}`,
		children: option.children.map((child) => ({
			key: child.value,
			value: child.value,
			title: child.label,
			fullTitle: formatSelectTitle(option.label, child.label),
			isLeaf: true,
			readableTitle: child.label,
			'data-cy': `parent-select-${option.label}/${child.label}`,
			children: child.children?.map((grandChild) => ({
				key: grandChild.value,
				value: grandChild.value,
				title: grandChild.label,
				isLeaf: true,
				readableTitle: grandChild.label,
				fullTitle: formatSelectTitle(child.label, grandChild.label),
				'data-cy': `parent-select-${child.label}/${grandChild.label}`,
			})),
		})),
	}));
}

export function formatSelectTitle(parentLabel, childLabel) {
	if (parentLabel === ALL_LOCATIONS) return childLabel;
	return (
		<>
			<Text className="select__parent-label">{parentLabel + ' / '}</Text>
			<Text>{childLabel}</Text>
		</>
	);
}

export function formatCompanies(
	data,
	merchants,
	showMerchants,
	parentCompanyID,
	showAll,
) {
	let companies = Object.values(data)
		.filter(({ active }) => active)
		.map(({ id: companyID, name, rest }) => {
			const companyMerchants = Object.values(merchants).filter(
				(merchant) => merchant.companyID === companyID,
			);
			const singleMerchant = companyMerchants.length === 1;
			return {
				id: companyID,
				value: JSON.stringify({
					companyID: companyID,
					merchantID:
						parentCompanyID && singleMerchant && showMerchants
							? companyMerchants[0].id
							: null,
				}),
				label: name,
				children: showMerchants
					? // If only one merchant don't create children array for cascader
					  singleMerchant
						? []
						: companyMerchants.map(({ id: merchantID, name, companyID }) => {
								return {
									value: JSON.stringify({
										companyID,
										merchantID,
									}),
									label: name,
								};
						  })
					: [],
				items: showMerchants
					? // If only one merchant don't create children array for cascader
					  singleMerchant
						? []
						: companyMerchants.map(({ id: merchantID, name, companyID }) => {
								return {
									value: JSON.stringify({
										companyID,
										merchantID,
									}),
									label: name,
								};
						  })
					: [],
				...rest,
			};
		});
	if (parentCompanyID && showAll && companies.length > 1) {
		companies = [
			{
				id: parentCompanyID,
				value: JSON.stringify({
					companyID: ALL_LOCATIONS,
					merchantID: null,
				}),
				label: ALL_LOCATIONS,
				name: ALL_LOCATIONS,
				companyTimezone: 'US/Eastern',
				children: companies,
				items: companies,
			},
		];
	}
	return companies;
}
