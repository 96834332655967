import { createMenuManagementAxiosInstance } from 'createAxiosInstance';
import {
	createAssetAxiosInstance,
	createCoreAxiosInstance,
} from 'createAxiosInstance';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import {
	DAY_TO_WEEKDAY,
	ITEMS,
	MODIFIERS,
	MODIFIER_GROUPS,
	EXPIRY_DATE,
	END_OF_DAY,
	START_OF_DAY,
	SUNDAY_ORDINAL,
	MONDAY_ORDINAL,
	PERCENTAGE,
	CUBOH_PREFERRED_COUNTRY_CODES,
	FULFILLMENT_TYPES,
} from 'constants/menuCheckout.constants';
import {
	SCHEDULE_DAY_OPTIONS,
	MIDNIGHT,
} from 'constants/menuManagement.constants';

import {
	normalizeByKey,
	snakeToCamelCase,
	stringToSnakeCase,
} from 'utils/format.helper';
import { parsePhoneNumber } from 'utils/helpers';
import { isEndTimePastMidnight } from 'utils/menuManagementHours.helper';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const loadMenu = async ({ companyID, merchantID, menuID }) => {
	const axiosInstance = await createMenuManagementAxiosInstance();
	const {
		data: { cubohMenu },
	} = await axiosInstance.get(
		`company/${companyID}/cuboh/merchant/${merchantID}/menus/detailed/full/${menuID}`,
	);
	const [cachedMenu] = cubohMenu;
	return formatCachedMenu(convertResponse(cachedMenu));
};

const convertResponse = (response) => {
	const parentKeys = Object.keys(response);
	parentKeys.forEach((key) => {
		const currentObj = response[key];
		delete response[key];
		const newKey = snakeToCamel(key);
		response[newKey] = currentObj;
		if (response[newKey] && typeof response[newKey] === 'object') {
			convertResponse(response[newKey]);
		}
	});
	return response;
};

const snakeToCamel = (string) => {
	const splitStringArr = string.split('_');
	const builtStr = splitStringArr.reduce((acc, curr, i) => {
		curr = i !== 0 ? curr[0].toUpperCase() + curr.slice(1) : curr;
		return acc + curr;
	}, '');
	return builtStr;
};

export const formatCachedMenu = (menu) => {
	const categories = (menu.cubohCategory ?? []).reduce(
		(accumulator, category) => {
			const uniqueId = `${category.uuid}_${category.parentUuidId}`;
			accumulator[uniqueId] = {
				...category,
				key: uniqueId,
			};
			return accumulator;
		},
		{},
	);

	const items = Object.values(categories ?? {})
		.filter((category) => category.cubohMainItem)
		.reduce((accumulator, currentCategory) => {
			currentCategory.cubohMainItem.forEach((item) => {
				const uniqueId = `${item.uuid}_${item.parentUuidId}`;
				accumulator[uniqueId] = {
					key: uniqueId,
					...item,
				};
			});
			return accumulator;
		}, {});

	const modifierGroups = Object.values(items ?? {})
		.filter((item) => item.cubohModifierGroup)
		.reduce((accumulator, currentItem) => {
			currentItem.cubohModifierGroup.forEach((modifierGroup) => {
				const uniqueId = `${modifierGroup?.uuid}_${modifierGroup?.parentUuidId}`;
				accumulator[uniqueId] = {
					key: uniqueId,
					...modifierGroup,
				};
			});

			return accumulator;
		}, {});

	const modifiers = Object.values(modifierGroups ?? {})
		.filter((modifierGroup) => modifierGroup.cubohModifier)
		.reduce((accumulator, currentModifierGroup) => {
			currentModifierGroup.cubohModifier.forEach((modifier) => {
				const uniqueId = `${modifier.uuid}_${modifier.parentUuidId}`;
				accumulator[uniqueId] = {
					key: uniqueId,
					...modifier,
				};
			});
			return accumulator;
		}, {});

	delete menu.cubohCategory;
	Object.values(categories ?? {}).forEach((category) => {
		delete category.cubohMainItem;
	});
	Object.values(items ?? {}).forEach((item) => {
		delete item.cubohModifierGroup;
	});
	Object.values(modifierGroups ?? {}).forEach((modifierGroup) => {
		delete modifierGroup.cubohModifier;
	});

	return {
		...menu,
		categories,
		items,
		modifierGroups,
		modifiers,
	};
};

export const loadStorefrontConfig = async ({
	companyName,
	companyID,
	isPreview,
}) => {
	const loadPreview = async () => {
		const axiosInstance = await createMenuManagementAxiosInstance();
		const response = await axiosInstance.get(
			`company/${companyID}/cuboh/storefront`,
		);
		return response.data.cubohStorefront;
	};

	const loadLive = async () => {
		const axios = await createAssetAxiosInstance();
		const response = await axios.get(
			`/company-storefronts/${companyName}.json?v=${Date.now()}`,
		);
		return response.data;
	};

	const storefront = await (isPreview ? loadPreview() : loadLive());
	let normalizedMenus = {};
	const merchants = storefront.merchants.reduce(
		(previous, { merchantId: id, menus, ...rest }) => {
			previous[id] = { ...rest, id, key: id };
			normalizedMenus = menus.reduce((previous, current) => {
				normalizedMenus[current.id] = {
					...current,
					key: current.id,
					merchant: id,
				};
				return previous;
			}, normalizedMenus);

			return previous;
		},
		{},
	);

	return {
		...storefront,
		addressLatitude: parseFloat(storefront.addressLatitude) || 0.0,
		addressLongitude: parseFloat(storefront.addressLongitude) || 0.0,
		merchants,
		menus: normalizedMenus,
	};
};

export const fetchStorefrontLocationsData = async (companyID) => {
	const axiosInstance = await createCoreAxiosInstance();
	const response = await axiosInstance.get(
		`api/v2/storefront/locations/company/${companyID}`,
	);
	return response.data;
};

export const sortByItemTypeOrdinal = (data, itemType) => (a, b) =>
	data[itemType][a].ordinal - data[itemType][b].ordinal;

export const findHighestMenuTaxRate = (items) => {
	const highest = items.reduce((accumulator, currentItem) => {
		if (currentItem.menuTaxRate > accumulator) {
			return currentItem.menuTaxRate;
		}
		return accumulator;
	}, 0);
	return parseFloat(highest.toFixed(2));
};

export const renderPickupTime = (pickupTimeMinutes) =>
	`${pickupTimeMinutes - 5} mins - ${pickupTimeMinutes + 5} mins`;

export const nameIsMissing = (name) => !name || name?.length < 2;
export const phoneNumberInvalid = (phoneNumber) => {
	return !parsePhoneNumber(phoneNumber).isValid;
};

export const additionalRequiredFieldIsMissing = (fieldValue) =>
	!fieldValue || fieldValue?.length < 2;

export const basketItemPrice = (item) =>
	(item.quantity *
		(item.price +
			item.subitems.reduce(
				(accumulator, { price, quantity = 1 }) =>
					accumulator + price * quantity,
				0,
			))) /
	100;

export const confirmSubscription = async ({ access }) => {
	const axios = await createCoreAxiosInstance();
	await axios.post('/api/v2/pusher/subscription', { access });
};

export const cancelOrder = async ({ access }) => {
	const axios = await createCoreAxiosInstance();
	await axios.post('/api/v2/pusher/cancel-order', { access });
};

export const emailInvalid = (email) =>
	!email ||
	!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email,
	);

export const splitMenuTime = (time) => {
	const [hour, minute] = time.split(':');
	return [parseInt(hour), parseInt(minute)];
};

export const generateHourRange = (
	hours = [],
	fulfillment_type = FULFILLMENT_TYPES.PICKUP,
	deliveryTimeMinutes = 30,
) => {
	const allOptions = [];

	hours.forEach(({ startTime, endTime, hasRollOver = false }) => {
		const deliveryStartTime = dayjs(startTime, 'HH:mm')
			.add(deliveryTimeMinutes, 'minutes')
			.format('HH:mm');

		const [startHour, startMinute] = splitMenuTime(
			fulfillment_type === FULFILLMENT_TYPES.DELIVERY
				? deliveryStartTime
				: startTime,
		);
		const [endHour, endMinute] = splitMenuTime(
			hasRollOver ? END_OF_DAY : endTime,
		);

		// Create all permutations, filter afterwards
		const options = [];
		for (let i = startHour; i <= endHour && i <= 23; i++) {
			options.push(...[`${i}:00`, `${i}:15`, `${i}:30`, `${i}:45`]);
		}

		const filteredOptions = options.filter((time) => {
			const [hour, minute] = splitMenuTime(time);

			if (startHour === endHour && hour === startHour) {
				return minute >= startMinute && minute <= endMinute;
			}

			if (hour === startHour) {
				return minute >= startMinute;
			}

			if (hour === endHour) {
				return minute <= endMinute;
			}

			return true;
		});
		allOptions.push(...filteredOptions);
	});
	return allOptions;
};

export const getNextDayIndex = ({ dayIndex }) => {
	const currentDay = SCHEDULE_DAY_OPTIONS.find(
		(option) => option.value === dayIndex,
	);
	if (!currentDay) return null;

	const nextOrdinal =
		currentDay.ordinal === SUNDAY_ORDINAL
			? MONDAY_ORDINAL
			: currentDay.ordinal + 1;
	return SCHEDULE_DAY_OPTIONS.find((option) => option.ordinal === nextOrdinal)
		.value;
};

export const getMenuHoursWithoutRollovers = (hours) => {
	return hours.reduce((accumulator, currentHours) => {
		if (
			isEndTimePastMidnight({
				start: currentHours.startTime,
				end: currentHours.endTime,
			})
		) {
			accumulator.push({
				startTime: currentHours.startTime,
				endTime: END_OF_DAY,
				dayIndex: currentHours.dayIndex,
			});
			accumulator.push({
				startTime: START_OF_DAY,
				endTime: currentHours.endTime,
				dayIndex: getNextDayIndex({ dayIndex: currentHours.dayIndex }),
			});
		} else {
			accumulator.push(currentHours);
		}
		return accumulator;
	}, []);
};

export const menuIsOpen = ({
	storefrontConfig,
	menuID,
	time = dayjs().unix(),
}) => {
	const { menus, storefrontTimezone } = storefrontConfig ?? {};
	const menu = menus?.[menuID];

	// If the menu does not exist, or has misconfigured hours
	// assume that it is closes.
	if (!menu || menu?.hours === null) return false;

	// Cast the Unix timestamp to a dayjs object taking into account
	// the storefronts timezone
	const momentTime = dayjs.unix(time).tz(storefrontTimezone);

	const timeDay = momentTime.format('ddd');
	const timeHour = momentTime.get('hour');
	const timeMinute = momentTime.get('minute');

	const menuHoursWithoutRollovers = getMenuHoursWithoutRollovers(menu.hours);
	const dayHours = menuHoursWithoutRollovers
		.filter(({ dayIndex }) => dayIndex === DAY_TO_WEEKDAY[timeDay])
		.filter(({ startTime, endTime }) => {
			const [startHour, startMinute] = splitMenuTime(startTime);
			const [endHour, endMinute] = splitMenuTime(endTime);

			// Convert everything to a minutes base for easy comparison
			// e,g, 8H 55M = 8 * 60 + 55, this way can compare minutes to minutes
			const startTimeToMinutesInDay = startHour * 60 + startMinute;
			const endTimeToMinutesInDay = endHour * 60 + endMinute;
			const timeToMinutesInDay = timeHour * 60 + timeMinute;

			return (
				timeToMinutesInDay >= startTimeToMinutesInDay &&
				timeToMinutesInDay <= endTimeToMinutesInDay &&
				startTime !== endTime
			);
		});

	return dayHours.length > 0;
};

/**
 * Given the storefront config, and shopping cart values, check if each item is unavailable
 * at the chosen pick up time.
 *
 * We rebind the index from the cart so that external functions can remove them via reducer actions.
 *
 * @param {storefrontConfig: Object, shoppingCart: Object, time: number} The store configuration and current cart
 * @returns {Object[]} Items which are not available for the chosen time
 */
export const getUnavailableMenuItems = ({
	storefrontConfig,
	items,
	time = dayjs().unix(),
}) => {
	return items
		.map((item, index) => ({ ...item, index }))
		.filter(
			(item) =>
				!menuIsOpen({
					storefrontConfig,
					menuID: item.menuID,
					time,
				}),
		);
};

export const formatAdditionalFields = (fields) => {
	// Add new key to fields
	const updatedFields = fields.map((field) => {
		return {
			...field,
			key: snakeToCamelCase(stringToSnakeCase(field.label)),
			value: null,
		};
	});
	return normalizeByKey(updatedFields, 'key');
};

export const hasInvalidRequiredAdditionalFields = (fields) => {
	let disabled = false;
	for (const field in fields) {
		if (
			fields[field].required &&
			additionalRequiredFieldIsMissing(fields[field].value)
		) {
			disabled = true;
			break;
		}
	}

	return disabled;
};

export const isMenuMissing = ({ storefrontMenus, menus, menuID }) => {
	return !storefrontMenus[menuID] || !menus[menuID];
};

export const isMerchantDisabled = ({ storefrontMerchants, merchantID }) => {
	return (
		!storefrontMerchants[merchantID] ||
		storefrontMerchants[merchantID]?.disabled
	);
};

export const isMerchantOrderingPaused = ({
	storefrontMerchants,
	merchantID,
}) => {
	return storefrontMerchants[merchantID]?.storefrontOrdersPaused;
};

export const isItemOrderingPaused = ({ menus, menuID, key, type }) => {
	return menus[menuID].data?.[type]?.[key]?.paused;
};

export const isItemMissing = ({ menus, menuID, key, type }) => {
	return !menus[menuID].data?.[type]?.[key];
};

export const isPriceDifferent = ({ menus, menuID, key, storedPrice, type }) => {
	return menus[menuID].data?.[type]?.[key]?.price?.price !== storedPrice;
};

export const isNameDifferent = ({ menus, menuID, key, storedName, type }) => {
	return menus[menuID]?.data?.[type]?.[key]?.name !== storedName;
};

export const isTaxRateDifferent = ({
	menus,
	menuID,
	key,
	storedTaxRate,
	type,
}) => {
	return (
		parseFloat(
			parseFloat(menus[menuID].data?.[type]?.[key].taxRate ?? 0.0).toFixed(2),
		) !== parseFloat(parseFloat(storedTaxRate).toFixed(2))
	);
};

export const isMenuTaxRateDifferent = ({
	menus,
	menuID,
	storedMenuTaxRate,
}) => {
	return (
		parseFloat(parseFloat(menus[menuID].data.taxRate ?? 0.0).toFixed(2)) !==
		parseFloat(parseFloat(storedMenuTaxRate).toFixed(2))
	);
};

export const isItemAvailable = ({
	storefrontConfig,
	menus,
	item,
	time = dayjs().unix(),
}) => {
	const storefrontMenus = storefrontConfig.menus;
	const storefrontMerchants = storefrontConfig.merchants;
	const { menuID, key, merchantID } = item;
	const storedName = item.name;
	const storedPrice = item.price;
	const storedTaxRate = item.taxRate;
	const storedMenuTaxRate = item.menuTaxRate;

	if (isMenuMissing({ storefrontMenus, menus, menuID })) {
		return { available: false, warning: 'Menu no longer available.' };
	}
	if (!menuIsOpen({ storefrontConfig, menuID, time })) {
		return { available: false, warning: 'Menu is currently closed.' };
	}
	if (isMerchantDisabled({ storefrontMerchants, merchantID })) {
		return { available: false, warning: 'Merchant no longer available.' };
	}
	if (isMerchantOrderingPaused({ storefrontMerchants, merchantID })) {
		return {
			available: false,
			warning: 'Online ordering for this merchant is currently unavailable.',
		};
	}
	if (isItemMissing({ menus, menuID, key, type: ITEMS })) {
		return {
			available: false,
			warning: `Item ${storedName} no longer available.`,
		};
	}
	if (isNameDifferent({ menus, menuID, key, storedName, type: ITEMS })) {
		return {
			available: false,
			warning: `Item ${storedName}'s name has changed.`,
		};
	}
	if (
		isPriceDifferent({
			menus,
			menuID,
			key,
			storedPrice,
			type: ITEMS,
		})
	) {
		return {
			available: false,
			warning: `Item ${storedName}'s price has changed.`,
		};
	}
	if (isTaxRateDifferent({ menus, menuID, key, storedTaxRate, type: ITEMS })) {
		return {
			available: false,
			warning: `Item ${storedName}'s tax rate has changed.`,
		};
	}
	if (isMenuTaxRateDifferent({ menus, menuID, key, storedMenuTaxRate })) {
		return {
			available: false,
			warning: `Menu tax rate has changed.`,
		};
	}
	if (isItemOrderingPaused({ menus, menuID, key, type: ITEMS })) {
		return {
			available: false,
			warning: `Item ${storedName} is currently unavailable.`,
		};
	}

	for (const subItem of item.subitems) {
		const modGroupKey = `${subItem.parentUuidId}_${item.uuid}`;
		const modKey = subItem.key;

		if (
			isItemMissing({ menus, menuID, key: modKey, type: MODIFIERS }) ||
			isItemMissing({ menus, menuID, key: modGroupKey, type: MODIFIER_GROUPS })
		) {
			return {
				available: false,
				warning: `Modifier ${subItem.name} no longer available.`,
			};
		}
		if (
			isNameDifferent({
				menus,
				menuID,
				key: modKey,
				storedName: subItem.name,
				type: MODIFIERS,
			})
		) {
			return {
				available: false,
				warning: `Modifier ${subItem.name}'s name has changed.`,
			};
		}
		if (
			isPriceDifferent({
				menus,
				menuID,
				key: modKey,
				storedPrice: subItem.price,
				type: MODIFIERS,
			})
		) {
			return {
				available: false,
				warning: `Modifier ${subItem.name}'s price has changed.`,
			};
		}
		if (
			isTaxRateDifferent({
				menus,
				menuID,
				key: modKey,
				storedTaxRate: subItem.taxRate,
				type: MODIFIERS,
			})
		) {
			return {
				available: false,
				warning: `Modifier ${subItem.name}'s tax rate has changed.`,
			};
		}
		if (
			subItem.quantity > 0 &&
			isItemOrderingPaused({ menus, menuID, key: modKey, type: MODIFIERS })
		) {
			return {
				available: false,
				warning: `Item ${subItem.name} is currently unavailable.`,
			};
		}
	}

	return { available: true, warning: '' };
};

export const updateStoredCart = ({ items, cartKey }) => {
	if (items.length) {
		const expiry = new Date(); // now
		expiry.setDate(expiry.getDate() + 1); // add one day
		const updatedCart = {
			[EXPIRY_DATE]: expiry.getTime(), // save in unix
			[ITEMS]: items,
		};
		window.localStorage.setItem(cartKey, JSON.stringify(updatedCart));
	} else {
		window.localStorage.removeItem(cartKey);
	}
};

export const calculatePercentage = (value, percentage) => {
	return ((percentage / 100) * value).toFixed(2);
};

export const calculateTip = (type, value, subtotalPrice) => {
	if (type === PERCENTAGE) {
		return calculatePercentage(subtotalPrice, value);
	}
	return value;
};

export const orderAgain = ({ order, company_id }) => {
	const items = order.reduce((accumulator, current) => {
		return [...accumulator, ...current.order_details];
	}, []);

	updateStoredCart({ items, cartKey: `savedCart_${company_id}` });
};

export const formItemDescription = ({ minimum, maximum }) => {
	if (minimum && maximum && minimum !== maximum) {
		return `Select between ${minimum} and ${maximum}`;
	}

	if (minimum && maximum && minimum === maximum) {
		return `Please select between ${minimum} options`;
	}

	if (minimum) {
		return `Select at least ${minimum}`;
	}

	if (minimum === maximum && minimum === 0) {
		return 'Select any number of items';
	}

	return `Select up to ${maximum}`;
};

export const getMerchantOrCompanyHours = (merchantMenus) => {
	const values = {};
	merchantMenus.forEach((menu) => {
		if (menu.disabled) return;
		(menu.hours ?? []).forEach(({ dayIndex, startTime, endTime }) => {
			if (values[dayIndex]) {
				values[dayIndex].push({ startTime, endTime });
			} else {
				values[dayIndex] = [{ startTime, endTime }];
			}
		});
	});
	return values;
};

// given 2 times (format: "05:30") return negative, 0, or positive number
export const compareTimes = (time1, time2) => {
	return parseInt(time1.replace(':', '')) - parseInt(time2.replace(':', ''));
};

export const hasRollOver = (startTime, endTime) => {
	return compareTimes(startTime, endTime) > 0;
};

export const mergeHoursIfOverlap = (
	{ startTime: start1, endTime: end1 },
	{ startTime: start2, endTime: end2 },
) => {
	const bothRollOvers = hasRollOver(start2, end2) && hasRollOver(start1, end1);
	const bothNoRollOvers =
		!hasRollOver(start2, end2) && !hasRollOver(start1, end1);
	const theyMatch = bothRollOvers || bothNoRollOvers;

	return (
		compareTimes(start1, start2) <= 0 &&
		(compareTimes(end1, start2) >= 0 || hasRollOver(start1, end1)) && {
			startTime: start1,
			endTime: theyMatch && compareTimes(end1, end2) >= 0 ? end1 : end2,
			hasRollOver: hasRollOver(start2, end2) || hasRollOver(start1, end1),
		}
	);
};

// solution pulled from https://stackoverflow.com/q/75488617/21236207
export const computeHours = (initialHours) => {
	const result = [...initialHours]
		.sort(({ startTime: start1 }, { startTime: start2 }) =>
			compareTimes(start1, start2),
		)
		.reduce((accumulator, current) => {
			let indexOfOverlap;
			let mergedHourSet;
			return (
				(indexOfOverlap = accumulator.findIndex(
					(element) =>
						(mergedHourSet =
							mergeHoursIfOverlap(element, current) ||
							mergeHoursIfOverlap(current, element)),
				)),
				(mergedHourSet && (accumulator[indexOfOverlap] = mergedHourSet)) ||
					accumulator.push(current),
				accumulator
			);
		}, []);

	return result;
};

export const filterClosedHours = (hours = []) => {
	return hours.filter(
		(hourSet) =>
			hourSet.startTime !== hourSet.endTime && hourSet.endTime !== MIDNIGHT,
	);
};

export const computeOpenHours = (rawHours = {}) => {
	const computed = {};
	const days = Object.keys(rawHours);
	days.forEach((day) => {
		const initialHours = rawHours[day];
		const result = computeHours(initialHours);
		const filteredResult = filterClosedHours(result);

		computed[day] = filteredResult;
	});
	return computed;
};

export const countryCodeSorter = (a, b) => {
	if (
		CUBOH_PREFERRED_COUNTRY_CODES.includes(a.short) &&
		CUBOH_PREFERRED_COUNTRY_CODES.includes(b.short)
	) {
		return 0;
	}
	if (CUBOH_PREFERRED_COUNTRY_CODES.includes(a.short)) {
		return -1;
	}
	if (CUBOH_PREFERRED_COUNTRY_CODES.includes(b.short)) {
		return 1;
	}
};
