import React from 'react';

import Icon from '@ant-design/icons';

import { ReactComponent as arrowLeft } from 'assets/icons/chownow-icons/arrow-left.svg';
import { ReactComponent as bell } from 'assets/icons/chownow-icons/bell.svg';
import { ReactComponent as calendar } from 'assets/icons/chownow-icons/calendar.svg';
import { ReactComponent as car } from 'assets/icons/chownow-icons/car.svg';
import { ReactComponent as caretDownFilled } from 'assets/icons/chownow-icons/caret-down-filled.svg';
import { ReactComponent as caretDown } from 'assets/icons/chownow-icons/caret-down.svg';
import { ReactComponent as caretUpFilled } from 'assets/icons/chownow-icons/caret-up-filled.svg';
import { ReactComponent as caretUp } from 'assets/icons/chownow-icons/caret-up.svg';
import { ReactComponent as cautionFilled } from 'assets/icons/chownow-icons/caution-filled.svg';
import { ReactComponent as caution } from 'assets/icons/chownow-icons/caution.svg';
import { ReactComponent as checkFilled } from 'assets/icons/chownow-icons/check-filled.svg';
import { ReactComponent as check } from 'assets/icons/chownow-icons/check.svg';
import { ReactComponent as clock } from 'assets/icons/chownow-icons/clock.svg';
import { ReactComponent as close } from 'assets/icons/chownow-icons/close.svg';
import { ReactComponent as copy } from 'assets/icons/chownow-icons/copy.svg';
import { ReactComponent as creditCard } from 'assets/icons/chownow-icons/credit-card.svg';
import { ReactComponent as delivery } from 'assets/icons/chownow-icons/delivery.svg';
import { ReactComponent as dineIn } from 'assets/icons/chownow-icons/dine-in.svg';
import { ReactComponent as edit } from 'assets/icons/chownow-icons/edit.svg';
import { ReactComponent as eye } from 'assets/icons/chownow-icons/eye.svg';
import { ReactComponent as infoFilled } from 'assets/icons/chownow-icons/info-filled.svg';
import { ReactComponent as menu } from 'assets/icons/chownow-icons/menu.svg';
import { ReactComponent as more } from 'assets/icons/chownow-icons/more.svg';
import { ReactComponent as phone } from 'assets/icons/chownow-icons/phone.svg';
import { ReactComponent as plusCircle } from 'assets/icons/chownow-icons/plus-circle.svg';
import { ReactComponent as receipt } from 'assets/icons/chownow-icons/receipt.svg';
import { ReactComponent as reset } from 'assets/icons/chownow-icons/reset.svg';
import { ReactComponent as search } from 'assets/icons/chownow-icons/search.svg';
import { ReactComponent as trash } from 'assets/icons/chownow-icons/trash.svg';
import { ReactComponent as unavailable } from 'assets/icons/chownow-icons/unavailable.svg';

export function ChowNowArrowLeft(props) {
	return <Icon component={arrowLeft} {...props} />;
}
export function ChowNowBell(props) {
	return <Icon component={bell} {...props} />;
}
export function ChowNowCalendar(props) {
	return <Icon component={calendar} {...props} />;
}
export function ChowNowCar(props) {
	return <Icon component={car} {...props} />;
}
export function ChowNowCaretDownFilled(props) {
	return <Icon component={caretDownFilled} {...props} />;
}
export function ChowNowCaretDown(props) {
	return <Icon component={caretDown} {...props} />;
}
export function ChowNowCaretUpFilled(props) {
	return <Icon component={caretUpFilled} {...props} />;
}
export function ChowNowCaretUp(props) {
	return <Icon component={caretUp} {...props} />;
}
export function ChowNowCautionFilled(props) {
	return <Icon component={cautionFilled} {...props} />;
}
export function ChowNowCaution(props) {
	return <Icon component={caution} {...props} />;
}
export function ChowNowCheck(props) {
	return <Icon component={check} {...props} />;
}
export function ChowNowClock(props) {
	return <Icon component={clock} {...props} />;
}
export function ChowNowClose(props) {
	return <Icon component={close} {...props} />;
}
export function ChowNowCopy(props) {
	return <Icon component={copy} {...props} />;
}
export function ChowNowCreditCard(props) {
	return <Icon component={creditCard} {...props} />;
}
export function ChowNowDelivery(props) {
	return <Icon component={delivery} {...props} />;
}
export function ChowNowDineIn(props) {
	return <Icon component={dineIn} {...props} />;
}
export function ChowNowEdit(props) {
	return <Icon component={edit} {...props} />;
}
export function ChowNowEye(props) {
	return <Icon component={eye} {...props} />;
}
export function ChowNowInfoFilled(props) {
	return <Icon component={infoFilled} {...props} />;
}
export function ChowNowMenu(props) {
	return <Icon component={menu} {...props} />;
}
export function ChowNowMore(props) {
	return <Icon component={more} {...props} />;
}
export function ChowNowPhone(props) {
	return <Icon component={phone} {...props} />;
}
export function ChowNowPlusCircle(props) {
	return <Icon component={plusCircle} {...props} />;
}
export function ChowNowReceipt(props) {
	return <Icon component={receipt} {...props} />;
}
export function ChowNowReset(props) {
	return <Icon component={reset} {...props} />;
}
export function ChowNowSearch(props) {
	return <Icon component={search} {...props} />;
}
export function ChowNowCheckFilled(props) {
	return <Icon component={checkFilled} {...props} />;
}
export function ChowNowTrash(props) {
	return <Icon component={trash} {...props} />;
}
export function ChowNowUnavailable(props) {
	return <Icon component={unavailable} {...props} />;
}
