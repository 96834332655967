import {
	atLeastOneLowerCase,
	atLeastOneNumber,
	atLeastOneSpecialCharacter,
	atleastOneUpperCase,
	atLeastTenCharactersLong,
	noEmptySpaces,
} from 'utils/helpers';

export const PERMISSIONS = {
	FETCH_INTEGRATIONS: 'Fetch Integrations',
	FETCH_MENU_ITEM: 'Fetch Menu Item',
	FETCH_ORDER: 'Fetch Order',
	CREATE_CREDENTIALS: 'Integration Credentials',
	TOGGLE_MENU_ITEM: 'Toggle Menu Item',
	UPDATE_COMPANY_SETTINGS: 'Update Company Settings',
	UPDATE_INTEGRATIONS: 'Update Integrations',
	UPDATE_ORDER: 'Update Order',
	VIEW_MERCHANTS: 'View Merchants',
};

export const PERMISSION_ERROR =
	"You don't have permission to perform this action.";

export const ALL_LOCATIONS = 'All Locations';
export const SOURCE = 'source';

/** Action Types */
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_ORDER_DISPLAY_TOKEN = 'SET_ORDER_DISPLAY_TOKEN';
export const SET_DATA = 'SET_DATA';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const UPDATE_PARENT_COMPANY = 'UPDATE_PARENT_COMPANY';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_MERCHANT = 'SET_SELECTED_MERCHANT';
export const SET_SELECTED_TIMEZONE = 'SET_SELECTED_TIMEZONE';
export const SET_PROXY_USER = 'SET_PROXY_USER';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const SET_USER_META = 'SET_USER_META';
export const SET_SOURCE = 'SET_SOURCE';

export const PASSWORD_RULES = [
	{
		condition: noEmptySpaces,
		label: 'No empty spaces',
	},
	{
		condition: atLeastTenCharactersLong,
		label: 'At least 10 characters long',
	},
	{
		condition: (value) =>
			atLeastOneSpecialCharacter(value) || atLeastOneNumber(value),
		label: 'At least 1 special character or number',
	},
	{
		condition: (value) =>
			atLeastOneLowerCase(value) && atleastOneUpperCase(value),
		label: 'At least 1 upper and lower case',
	},
];
