export const PAYMENT_GATEWAYS = {
	stripe: {
		key: 'stripe',
		name: 'Stripe',
		category: 'Payments',
		color: 'blue',
		disableToggle: false,
		excerpt: 'Collect payments and issue refunds. There are no monthly fees.',
		summary:
			'Stripe is an easy to use, low barrier payment gateway. There are no monthly fees.',
		support: null,
		storefrontDescription:
			'Offer the widest range of payment methods: cards, PayPal, etc. Benefits from all PayPal expertise and advantages. Give access to relevant local payment methods for customers around the globe.',
		imageUrl:
			'https://cuboh-assets.s3.amazonaws.com/android-image-resources/stripe-small.png',
		connectionUrl:
			'https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=cuboh_stripe_client_id&scope=read_write&state=cuboh_company_id',
	},
};

export const COMPONENTS_WITH_RULES = [
	'Input',
	'Password',
	'RadioGroup',
	'Select',
];

export const INTEGRATION_TYPE_CATEGORIES = {
	BRANDED_ONLINE_ORDERING: 'Branded Online Ordering',
	ONLINE_FOOD_ORDERING: 'Online Food Ordering',
	ORDER_AHEAD: 'Order Ahead',
};

export const SUBMIT_ACTIONS = {
	SUBMIT: 'submit',
	CONNECT: 'connect',
	VERIFY: 'verify',
	OK: 'ok',
	CONTINUE: 'continue',
};

export const INTEGRATION_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	PENDING: 'pending',
};

export const CORE_ACTIONS = {
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
};
export const SKIP_THE_DISHES = 'SKIP';

export const SKIP_THE_DISHES_FULL_APP_KEY = 'SKIPTHEDISHES';

export const CHOWNOW = 'CHOWNOW';
export const DOORDASH = 'DOORDASH';

export const V1_ONBOARDING_APPS = [SKIP_THE_DISHES, 'UBEREATS', 'GRUBHUB'];

export const PLATFORMS = 'platforms';
export const PAYMENTS = 'payments';
export const POS = 'POS';
export const POS_INTEGRATION_TYPE = 'pos';
export const HIDE_MERCHANTS_TABS = [PAYMENTS, POS];

export const LOCATIONS = 'locations';
export const POPULAR = [
	'chownow',
	'doordash',
	'skip',
	'ubereats',
	'grubhub',
	'stripe',
	'square',
	'lightspeed',
];
export const REFERRAL = [
	'doordash',
	'skip',
	'ubereats',
	'grubhub',
	'chownow',
	'ritual',
	'flipdish',
];
export const HIDE_PLATFORMS = ['moduurn', 'mixbowl', 'freshbytes'];

export const EXTRA_INFO = 'extraInfo';

export const REFERRAL_PARTNERS = 'referral_partners';

export const FRESH_KDS = 'FRESH_KDS';
export const POINT_OF_SALE_RESOURCE = 'POINT_OF_SALE_RESOURCE';
export const COLUMNS = {
	xs: 1,
	sm: 1,
	md: 1,
	lg: 2,
	xl: 2,
	xxl: 3,
};
export const ONBOARDING_STATUS_CODE_MAP = {
	DOORDASH: {
		DUPLICATE_LOCATION_ID:
			'This location_ID / provider_type combination is being used on another POS store. This partner location is already integrated with a store on DoorDash. Please contact DoorDash Support if this location is incorrectly integrated with an existing DoorDash Store ID.',
		DOORDASH_RETAIL_STORE:
			'This store is using a non-restaurant UI on DoorDash for performance purposes and is not eligible to integrate.',
		VIRTUAL_BRAND_DETECTED:
			'This store is identified as a virtual concept, and the integration you are attempting to onboard does not support unique location_id values across virtual concepts. This store is therefore ineligible to integrate. Please contact DoorDash Support if you believe this to be incorrect for this store.',
		SELF_DELIVERY_DETECTED:
			'The store is currently enabled for Self Delivery, but your integration does not support Self Delivery. Please contact DoorDash Support to disable Self-Delivery and process the financial rate change associated to unblock this store onboarding.',
		DOORDASH_DRIVE_STORE:
			'DoorDash Drive stores cannot be onboarded to DoorDash Marketplace.',
		STORE_LIVE_ON_REQUESTED_POS_PROVIDER:
			'This store is already live on the requested POS integration with this location ID.',
		SFDC_ACCOUNT_RECORD_NOT_FOUND:
			'No existing internal DoorDash account found for the store; DoorDash needs to create an internal account for this store before you can proceed. This is not expected to occur; please contact DoorDash for support in resolving if this error is encountered.',
		MENU_PULLING_REQUEST_FAILURE:
			'Menu Job failed due to internal issue. Please retry the menu ingestion.',
		STORE_HOURS_NOT_POPULATED_FAILURE:
			'Menu Job failed due to missing menu hours. Please ensure the menu(s) being ingested have valid hours and retry the menu ingestion.',
		MENU_BLANK_FAILURE:
			'Menu Job succeeded, but the menu has no items. Please ensure the menu(s) being ingested have valid contents and retry the menu ingestion.',
		MENU_JOB_FAILURE:
			'Menu Job failed due to issues with the menu. This may be due to missing contents, null category/item names, or other issues with the menu.',
		MENU_COMPLIANCE_FAILURE:
			'Menu Job failed due to identified as having non-compliant items. Please ensure you have completed the proper compliance forms on DoorDash or remove the non-compliant items, and retry the menu ingestion. ',
	},
};

export const ONBOARDING_STATUS_MAP = {
	DOORDASH: {
		ABANDONED: 'Abandoned',
		MENU_BLOCK: 'Menu Block',
		ACTIVATION_BLOCK: 'Activation Block',
		MENU_QUALIFIED: 'Menu Qualified',
		INTEGRATION_ACTIVATED: 'Integration Activated',
		INTEGRATION_PENDING: 'Integration Pending',
		MENU_REQUESTED: 'Menu Requested',
	},
};

export const FAILURE_STATUS = 'failure';
export const SUCCESS_STATUS = 'success';
