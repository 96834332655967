import { useEffect, useState, useCallback } from 'react';

import { notification } from 'antd';
import Pusher from 'pusher-js';

import {
	notificationType,
	DEFAULT_MESSAGE_DURATION,
} from 'constants/constants';
import {
	STORE_STATUS_UPDATED,
	PUSHER_ACTIVITY_TIMEOUT_MILLISECONDS,
	REFRESH_APP,
} from 'constants/notification.constants';
import {
	ACTIVATE,
	ACTIVATED,
	DEACTIVATED,
} from 'constants/ordersControl.constants';

import { usePollIntegration } from 'hooks/usePollIntegration';
import { usePollMigration } from 'hooks/usePollMigration';
import { usePollOrchestrator } from 'hooks/usePollOrchestrator';

// Provider hook that creates auth object and handles state
export default function useProvideNotify() {
	const [kickstart, setKickStart] = useState(false);
	const [workerLedger, setWorkerLedger] = useState([]);
	const [finishTask, setFinishTask] = useState('');

	/*PUSHER STATE */

	// Holds a reference to the pusher channel
	const [pusher, setPusher] = useState(null);
	// Holds a reference to the event channel, needed for disconnect
	const [channels, setChannels] = useState(null);
	/**
	 * String value for the channel name, setting it will cause
	 * pusher to create a new channel ref
	 */
	const [channelNames, setChannelNames] = useState(null);

	useEffect(
		() =>
			notification.config({
				//TODO DASH-605 update the notification provider to support prefixCls
				// prefixCls: 'cuboh', // this is needed to prefix 'cuboh' to classnames.
				duration: DEFAULT_MESSAGE_DURATION,
				maxCount: 3,
			}),
		[],
	);

	useEffect(() => {
		if (!channelNames) return;

		const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
			cluster: process.env.REACT_APP_PUSHER_CLUSTER,
			activityTimeout: PUSHER_ACTIVITY_TIMEOUT_MILLISECONDS,
		});

		const pusherChannels = channelNames?.map((channelName) =>
			pusher.subscribe(channelName),
		);

		const globalChannelName = `${process.env.REACT_APP_WS_STAGE}-cuboh-dashboard`;
		const channel = pusher.subscribe(globalChannelName);
		channel.bind_global((event) => {
			if (event === REFRESH_APP) {
				setTimeout(() => {
					window.location.reload(true);
				}, Math.floor(Math.random() * 30000));
			}
		});

		setChannels(pusherChannels);
		setPusher(pusher);

		/** Cleanup on unmount/re-render */
		return () => {
			channelNames.forEach((channelName) => pusher.subscribe(channelName));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channelNames]);

	const onStoreStatusUpdate = ({ status, username, apps }) => {
		const actionPerformed = status === ACTIVATE ? ACTIVATED : DEACTIVATED;
		const appString = apps.join(', ');
		notification.info({
			message: 'Store Status Update',
			description: `${username} ${actionPerformed} the following platforms: ${appString}`,
		});
	};

	// Bind global channel events here - otherwise use exported
	useEffect(() => {
		if (!channels) return;
		channels.forEach((channel) => {
			channel.unbind_global();
			channel.bind_global((event, data) => {
				switch (event) {
					case STORE_STATUS_UPDATED:
						onStoreStatusUpdate(data);
						break;
					default:
						return;
				}
			});
		});
	}, [channels]);

	/** Polling implementation */
	const pollIntegration = usePollIntegration(setFinishTask);
	const pollOrchestrator = usePollOrchestrator(setFinishTask);
	const pollMigration = usePollMigration(setFinishTask);

	useEffect(() => {
		if (finishTask !== '') {
			localStorage.removeItem(finishTask);
			setWorkerLedger((workerLedger) => [
				...workerLedger.filter((key) => key !== finishTask),
			]);
			setFinishTask('');
		}
	}, [finishTask]);

	/**
	 * Get implementation type - returns pseudo 'template methods' from hooks
	 * @param {notificationType} notificationType: String Enum
	 * @returns object of callback functions
	 */
	const getImplementation = useCallback(
		(type) => {
			switch (type) {
				case notificationType.integration:
					return pollIntegration;
				case notificationType.orchestrator:
					return pollOrchestrator;
				case notificationType.migration:
					return pollMigration;
				default:
					console.error(`Unable to start worker job of type: ${type}`);
					return null;
			}
		},
		[pollIntegration, pollOrchestrator, pollMigration],
	);

	// Checking for previously active integration jobs.
	useEffect(() => {
		Object.keys(localStorage)
			.filter((key) => key.includes('active'))
			.filter((key) => !workerLedger.includes(key))
			.forEach((key) => {
				const { type } = JSON.parse(localStorage.getItem(key));
				const { parseKey, onKickstart, startWorker } = getImplementation(type);

				const keyData = parseKey(key);

				/** Perform kickstart task */
				onKickstart(keyData);

				//Ledger checks for already started jobs, if id is found don't kick start the worker.
				startWorker(keyData);
				setWorkerLedger((workerLedger) => [...workerLedger, key]);
			});
		// @TODO - refactor this - we need seperate on mount from kickstarting jobs from other components
		// eslint-disable-next-line
	}, [kickstart, getImplementation]);

	/**
	 * Start a worker job
	 *
	 * @param {notificationType} type: The type of notification to create
	 * @param {*} jobInfo: Job Key
	 * @param {*} meta: Job Value
	 * @returns The key of the job that was added to storage
	 */
	const createNotification = (type, jobInfo, meta) => {
		const { workerKey } = getImplementation(type);

		//Store job in local storage in case of navigation or page refresh.
		localStorage.setItem(workerKey(jobInfo), JSON.stringify({ ...meta, type }));

		// Set the kickstart for the hook so that it displays active tasks
		setKickStart(!kickstart);

		return workerKey;
	};

	return {
		createNotification,
		workerLedger,
		finishTask,
		kickstart,
		setChannelNames,
		pusher,
		channels,
		channelNames,
	};
}
