import {
	SET_IS_LOADING,
	SET_ERROR,
	SET_DATA,
	UPDATE_COMPANY,
	UPDATE_MERCHANT,
	UPDATE_PARENT_COMPANY,
	SET_SELECTED_COMPANY,
	SET_SELECTED_MERCHANT,
	SET_SELECTED_TIMEZONE,
	SET_PROXY_USER,
	SET_ORDER_DISPLAY_TOKEN,
	SET_FEATURE_FLAGS,
	SET_USER_META,
	SET_SOURCE,
} from 'constants/auth.constants';

import { formatCompanyID, formatMerchantID } from 'utils/auth.helper';

export const setIsLoading = ({ isLoading }) => ({
	type: SET_IS_LOADING,
	isLoading,
});

export const setOrderDisplayToken = (orderDisplayToken) => ({
	type: SET_ORDER_DISPLAY_TOKEN,
	orderDisplayToken,
});

export const setError = ({ hasError, message }) => ({
	type: SET_ERROR,
	hasError,
	message,
});

export const setData = ({ data }) => ({
	type: SET_DATA,
	data,
});

export const setSource = ({ source }) => ({
	type: SET_SOURCE,
	source,
});

export const setFeatureFlags = ({ featureFlags }) => ({
	type: SET_FEATURE_FLAGS,
	featureFlags,
});

export const updateCompany = ({ id, payload }) => ({
	type: UPDATE_COMPANY,
	id,
	payload,
});

export const updateMerchant = ({ id, payload }) => ({
	type: UPDATE_MERCHANT,
	id,
	payload,
});

export const updateParentCompany = ({ parentCompany }) => ({
	type: UPDATE_PARENT_COMPANY,
	parentCompany,
});

export const setSelectedCompanyID = ({ companyID }) => ({
	type: SET_SELECTED_COMPANY,
	companyID: formatCompanyID(companyID),
});

export const setSelectedMerchantID = ({ merchantID }) => ({
	type: SET_SELECTED_MERCHANT,
	merchantID: formatMerchantID(merchantID),
});

export const setSelectedTimezone = ({ timezone }) => ({
	type: SET_SELECTED_TIMEZONE,
	timezone,
});

export const setProxyUserID = ({ proxyUserID }) => ({
	type: SET_PROXY_USER,
	proxyUserID,
});

export const setUserMeta = ({ userMeta }) => ({
	type: SET_USER_META,
	userMeta,
});
