import React, { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { createCoreAxiosInstance } from 'createAxiosInstance';
import { useCookies } from 'react-cookie';
import { X } from 'react-feather';
import Cookies from 'universal-cookie';

import { SOURCE_CHOWNOW } from 'constants/chownow.constants';
import {
	ACCESS,
	CUBOH_ACCESS,
	CUBOH_REFRESH,
	REFRESH,
} from 'constants/constants';

import * as authHelper from 'utils/auth.helper';

/**
 * A reusable hook for managing a set of access tokens
 *
 * @param {refreshOnPageLoad} bool: Boolean to perform a refresh on the hook mounting
 * @param {keepTokenAlive} bool: Boolean on whether to mount a timer to auto-refresh the token
 * @param {onLogin} function: Callback for when tokens are set via login function
 * @param {onLogout} function: Callback for when tokens are cleared / token fails to refresh
 */

const DEFAULT_PARAMS = {
	refreshOnPageLoad: false,
	keepTokenAlive: false,
	onLogin: () => {},
	onLogout: () => {},
};
const FROM_CHOWNOW_COOKIE = 'fromChowNowCookie';
const REFRESH_INTERVAL_12_MINTUES = 720000;

export default function useAuthToken(props = DEFAULT_PARAMS) {
	const [cookies] = useCookies(['cuboh_access', 'cuboh_refresh']);
	const [fromChownowCookie, setFromChownowCookie] = useState(false);
	const { keepTokenAlive, onLogout, onLogin } = {
		...DEFAULT_PARAMS,
		...props,
	};
	const isSourceChowNowCookie =
		authHelper.getSourceFromCookie() === SOURCE_CHOWNOW;

	if (isSourceChowNowCookie) {
		localStorage.setItem(FROM_CHOWNOW_COOKIE, true);
	}
	useEffect(() => {
		if (cookies?.cuboh_access) {
			setFromChownowCookie(true);
			return;
		}
		if (fromChownowCookie && !cookies?.cuboh_access) {
			localStorage.clear();
			const redirect =
				process.env.REACT_APP_CHOWNOW_DASHBOARD_URL_WITH_REDIRECT ||
				'https://dashboard.chownow.com/';
			window.location.href = redirect;
		}
	}, [cookies, fromChownowCookie]);
	const [lastRefreshed, setLastRefreshed] = useState(new Date());

	const getAccessToken = useCallback(() => {
		return authHelper.getAccessToken();
	}, []);

	const setAccessToken = useCallback(
		(accessToken) => window.localStorage.setItem(ACCESS, accessToken),
		[],
	);

	const getRefreshToken = useCallback(() => {
		return authHelper.getRefreshToken();
	}, []);

	const setRefreshToken = useCallback(
		(refreshToken) => window.localStorage.setItem(REFRESH, refreshToken),
		[],
	);

	const clearTokens = useCallback(() => {
		[ACCESS, REFRESH].forEach((key) => window.localStorage.removeItem(key));
	}, []);

	const clearChownowCookies = () => {
		const cookies = new Cookies(null, { path: '/' });
		cookies.remove(CUBOH_ACCESS, { path: '/' });
		cookies.remove(CUBOH_REFRESH, { path: '/' });
	};

	const login = useCallback(
		async (values = {}) => {
			try {
				clearTokens();
				const { access, refresh } = await authHelper.login(values);
				setAccessToken(access);
				setRefreshToken(refresh);
				await onLogin();
			} catch (error) {
				message.error({
					className: 'cuboh-message-dark-theme',
					icon: <X className="icon-fill__danger" size="18" />,
					content: 'Invalid username or password. Please try again.',
				});
			}
		},
		[clearTokens, setAccessToken, setRefreshToken, onLogin],
	);

	/**
	 * Function to logout, clear any tokens
	 * and perform any additional callbacks bound into the hook
	 */
	const logout = useCallback(async () => {
		if (isSourceChowNowCookie || localStorage.getItem(FROM_CHOWNOW_COOKIE)) {
			clearChownowCookies();
			localStorage.clear();
			const redirect =
				process.env.REACT_APP_CHOWNOW_DASHBOARD_URL_WITH_REDIRECT ||
				'https://dashboard.chownow.com/';
			window.location.href = redirect;
		}
		clearTokens();
		onLogout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearTokens, onLogout]);

	/** Perform a token refresh */
	const refreshUsersToken = useCallback(async () => {
		try {
			const refreshToken = getRefreshToken();
			const accessToken = getAccessToken();
			if (!refreshToken || !accessToken) {
				if (isSourceChowNowCookie || localStorage.getItem(FROM_CHOWNOW_COOKIE))
					logout();
				return;
			}
			if (isSourceChowNowCookie) {
				const { data } = await axios.post(
					process.env.REACT_APP_CHOWNOW_TOKEN_REFRESH_URL,
					{ refresh_token: refreshToken },
				);
				setAccessToken(data.access_token);
			} else {
				const axiosInstance = await createCoreAxiosInstance();
				const { data } = await axiosInstance.post('api/v2/token/refresh', {
					refresh: refreshToken,
				});
				setAccessToken(data.access);
			}
		} catch (error) {
			logout();
			console.error(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getRefreshToken, getAccessToken, setAccessToken, logout]);

	useEffect(() => {
		const refresh = async () => {
			await refreshUsersToken();
		};
		refresh();
	}, [refreshUsersToken]);

	useEffect(() => {
		if (!keepTokenAlive) return;

		// Refresh every 3 minutes
		const interval = setInterval(async () => {
			await refreshUsersToken();
			setLastRefreshed(new Date());
		}, REFRESH_INTERVAL_12_MINTUES);
		return () => clearInterval(interval);
	}, [lastRefreshed, refreshUsersToken, keepTokenAlive]);

	return {
		refreshUsersToken,
		getAccessToken,
		setAccessToken,
		getRefreshToken,
		setRefreshToken,
		clearTokens,
		logout,
		login,
	};
}
