import { useEffect } from 'react';

import { useLastLocation } from 'react-router-dom-last-location';

import { useRouter } from 'hooks/useRouter';

export default function useBindRoute(key, callback) {
	const { query } = useRouter();

	// Ignore this - we want to wrap it regardless to preserve the initial function
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const lastLocation = useLastLocation();

	useEffect(() => {
		if (key in query) {
			callback(query[key], query, key);
			return;
		}

		const splitQueryParams = lastLocation?.search
			?.replace('?', '')
			.split('&')
			.map((param) => param.split('=')[0]);

		// If the last route contained the query param, call it with null when unset
		// e.g.
		// route?foo=bar&baz=qux -> route?baz=qux will invoke null on foo callback
		if (splitQueryParams?.indexOf(key) >= 0) {
			callback(null, query, key);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callback, key, lastLocation?.search]);
}
