import React from 'react';

import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

Fader.propTypes = {
	children: PropTypes.node,
	value: PropTypes.any,
};

export default function Fader({ children, value }) {
	return (
		<SwitchTransition mode={'out-in'}>
			<CSSTransition
				key={value}
				addEndListener={(node, done) => {
					node.addEventListener('transitionend', done, false);
				}}
				classNames="fader"
			>
				<div className={value}>{children}</div>
			</CSSTransition>
		</SwitchTransition>
	);
}
