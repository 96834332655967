export const SOURCE_CHOWNOW_ALLOWED_PATHS = [
	'/menu/management',
	'/analytics',
	'/reports/order',
	'/reports/control',
	'/reports/cancellations',
	'/reports/adjustment',
	'/reports/storefront-order',
	'/reports/pos-error',
	'/reports/item',
	'/reports/modifier',
	'/login',
];
export const SOURCE_CHOWNOW = 'chownow';
export const SOURCE_CUBOH = 'cuboh';

export const CHOWNOW_MENU_VIEW = 'chownowMenu';
export const CHOWNOW_ANALYTICS_VIEW = 'chownowData';
export const CHOWNOW_REPORTS_VIEW = 'chownowReport';
