import React from 'react';

import StorefrontAndKioskContext from 'context/StorefrontAndKioskContext';
import PropTypes from 'prop-types';

import useProvideStorefrontAndKiosk from 'hooks/useProvideStorefrontAndKiosk';

export function StorefrontAndKioskProvider({ children }) {
	const value = useProvideStorefrontAndKiosk();
	return (
		<StorefrontAndKioskContext.Provider value={value}>
			{children}
		</StorefrontAndKioskContext.Provider>
	);
}

StorefrontAndKioskProvider.propTypes = {
	children: PropTypes.node,
};

export default StorefrontAndKioskProvider;
