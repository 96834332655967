import React from 'react';

import { notification, Progress, Avatar, Select } from 'antd';
import { wrap, proxy } from 'comlink';
import { createCoreAxiosInstance } from 'createAxiosInstance';

import { appColors } from 'constants/colors.constants';
import { ACCESS } from 'constants/constants';

const { Option } = Select;

export function usePollIntegration(finishTask) {
	const startWorker = async (jobInfo) => {
		const { reqID, stage } = jobInfo;

		const Poll = wrap(new Worker('/workers/pollworker.js'));
		const instance = new Poll({
			method: 'GET',
			url: `${process.env.REACT_APP_SERVER_URL}/appIntegration?req_id=${reqID}&stage=${stage}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem(ACCESS)}`,
			},
		});
		(await instance).pollAPI(
			proxy((status, data) => {
				if (status === 200) {
					callback({
						status: 'success',
						reqID,
						stage,
						multiLocation: data.multi_location,
						locationList: data.location_list,
						msg: data.response,
					});
					return true;
				}
				return false;
			}),
			proxy((status, data) => {
				callback({
					status: 'exception',
					reqID,
					stage,
					msg: data.response ?? data.detail,
				});
				return true;
			}),
			proxy(() => ({
				Authorization: `Bearer ${window.localStorage.getItem(ACCESS)}`,
			})),
		);
	};

	const workerKey = (jobInfo) => `active_${jobInfo.reqID}_${jobInfo.stage}`;

	const parseKey = (key) => {
		const meta = JSON.parse(localStorage.getItem(key));
		const jobInfo = key.split('_');
		return { reqID: jobInfo[1], stage: jobInfo[2], meta };
	};

	const openNotification = (
		reqID,
		msg,
		meta,
		locationDropdown,
		strokeColor = { '0%': appColors.info, '100%': appColors.info },
	) => {
		notification['info']({
			key: reqID,
			message: msg,
			btn: locationDropdown,
			description: (
				<Progress
					strokeColor={strokeColor}
					status={'active'}
					percent={100}
					showInfo={false}
					size="small"
				/>
			),
			duration: 0,
			icon: (
				<Avatar
					className="avatar--notify"
					size={32}
					src={`/logos/${meta.key}.png`}
				/>
			),
		});
	};

	const callback = (response) => {
		const { reqID, msg, stage, status } = response;
		//Default Green
		let strokeColor = {
			'0%': appColors.success,
			'100%': appColors.success,
		};
		const jobInfo = { reqID, stage };
		const meta = JSON.parse(localStorage.getItem(workerKey(jobInfo)));

		let locationDropdown;
		if (response.multiLocation) {
			//Orange Requiring Action
			strokeColor = {
				'0%': appColors.warning,
				'100%': appColors.warning,
			};
			locationDropdown = (
				<Select
					className="select--notify"
					showSearch
					autoFocus={true}
					style={{ width: 288 }}
					placeholder="Select a location"
					optionFilterProp="children"
					onSelect={(e) => onLocationClick(e, response, meta)}
				>
					{response.locationList.map((location, i) => (
						<Option key={i} value={location}>
							{location}
						</Option>
					))}
				</Select>
			);
		}

		if (response.status !== 'success') {
			//Red - Invalid Credentials etc..
			strokeColor = {
				'0%': appColors.danger,
				'100%': appColors.danger,
			};

			finishTask(workerKey({ reqID, stage }));
		}
		openNotification(reqID, msg, meta, locationDropdown, strokeColor);
		finishTask(workerKey({ reqID, stage }));

		//Start New Worker at Stage 2
		if (stage === '1' && !response.multiLocation && status !== 'exception') {
			//Update LocalStorage to a Stage 2 value for job.
			localStorage.setItem(
				workerKey({ reqID, stage: 2 }),
				JSON.stringify(meta),
			);
		}
	};

	const onLocationClick = async (e, response, meta) => {
		const { reqID } = response;
		const selectedCompany = e;

		const patchValues = {
			req_id: reqID,
			selected_location: selectedCompany,
			appname: meta.appname,
		};

		openNotification(
			reqID,
			`Checking location selection for ${meta.name}.`,
			meta,
		);
		startWorker({ reqID, stage: 2 });

		//Update LocalStorage to a Stage 2 value for job.
		localStorage.setItem(workerKey({ reqID, stage: 2 }), JSON.stringify(meta));

		try {
			const axiosInstance = await createCoreAxiosInstance();
			await axiosInstance.patch(`/appIntegration?req_id=${reqID}`, patchValues);
		} catch (error) {
			console.error(error);
		}
	};

	const onKickstart = ({ reqID, meta }) =>
		openNotification(
			reqID,
			`We are in the process of validating your credentials for ${meta.name}`,
			meta,
		);

	return {
		parseKey,
		workerKey,
		onKickstart,
		startWorker,
	};
}
