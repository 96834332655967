import 'es6-shim';
import React from 'react';

import { ConfigProvider } from 'antd';
import AuthProvider from 'context/AuthProvider';
import ResponsiveLayoutProvider from 'context/ResponsiveLayoutProvider';
import StorefrontAndKioskProvider from 'context/StorefrontAndKioskProvider';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import routes from 'config/router.config';
import { dashboardTheme } from 'config/theme.config';

import { useAuth } from 'hooks/useAuth';

import Helm from 'components/Helm';
import Loader from 'components/Loader';
import LoggingErrorBoundary from 'components/LoggingErrorBoundary';
import ResetPasswordForm from 'components/Login/ResetPasswordForm';

ConfigProvider.config({ prefixCls: 'cuboh' });

const CompanyStorefrontConfig = Loader(() =>
	import('components/Storefront/Manage/CompanyStorefrontConfig'),
);

const Storefront = Loader(() => import('containers/Storefront'));
const SetToken = Loader(() => import('components/SetToken'));
const AuthForm = Loader(() => import('containers/AuthForm'));
const AppLayout = Loader(() => import('layouts/AppLayout'));
const OrderDisplayLayout = Loader(() => import('layouts/OrderDisplayLayout'));
const Exception = Loader(() => import('exceptions/404'));
const Register = Loader(() => import('containers/Register'));
const LoginForm = Loader(() => import('components/Login/LoginForm'));
const OrdersDisplayScreen = Loader(() =>
	import('containers/OrdersDisplayScreen'),
);
const ForgotPassword = Loader(() => import('components/Login/ForgotPassword'));

App.propTypes = {
	container: PropTypes.node,
	layout: PropTypes.node,
};

function App() {
	const elementCheck = (route, currentSubRoute) => {
		if (currentSubRoute.container) {
			const { container: Component } = currentSubRoute;
			return (
				<Route
					exact
					key={currentSubRoute.name}
					{...currentSubRoute}
					element={
						<AppLayout {...route}>
							<Component />
						</AppLayout>
					}
				/>
			);
		}
		return <Route exact key={currentSubRoute.name} {...currentSubRoute} />;
	};

	// Default application
	return (
		<ConfigProvider theme={dashboardTheme}>
			<AuthProvider>
				<StorefrontAndKioskProvider>
					<ResponsiveLayoutProvider>
						<LoggingErrorBoundary>
							<Routes>
								<Route
									path={'/login'}
									element={
										<AuthForm>
											<LoginForm authHook={useAuth} />
										</AuthForm>
									}
								/>
								<Route
									path={'/forgot-password'}
									element={
										<AuthForm>
											<ForgotPassword />
										</AuthForm>
									}
								/>
								<Route
									path={'/reset-password'}
									element={
										<AuthForm>
											<ResetPasswordForm />
										</AuthForm>
									}
								/>

								<Route exact path="/set-tokens" element={<SetToken />} />
								<Route
									exact
									path={'/manage/storefronts'}
									element={
										<AppLayout>
											<Storefront>
												<CompanyStorefrontConfig />
											</Storefront>
										</AppLayout>
									}
								/>

								<Route exact path="/register" element={<Register />} />
								<Route
									path="/orders-display"
									element={
										<OrderDisplayLayout>
											<OrdersDisplayScreen />
										</OrderDisplayLayout>
									}
								/>

								{routes.map((route) => {
									const { container: Component } = route;
									return route.routes ? (
										// create routes for all of the subroutes (and their routes)
										route.routes.reduce((accumulator, currentSubRoute) => {
											if (currentSubRoute.routes) {
												currentSubRoute.routes.forEach((subSubRoute) => {
													const { container: Component } = subSubRoute;
													accumulator.push(
														<Route
															exact
															key={subSubRoute.name}
															{...subSubRoute}
															element={
																<AppLayout {...route}>
																	<Component />
																</AppLayout>
															}
														/>,
													);
												});
											}
											accumulator.push(elementCheck(route, currentSubRoute));

											return accumulator;
										}, [])
									) : (
										<Route
											key={route.name}
											exact
											{...route}
											element={
												<AppLayout {...route}>
													<Component />
												</AppLayout>
											}
										/>
									);
								})}
								<Route element={<Exception />} />
							</Routes>
							<Helm />
						</LoggingErrorBoundary>
					</ResponsiveLayoutProvider>
				</StorefrontAndKioskProvider>
			</AuthProvider>
		</ConfigProvider>
	);
}

export default App;
