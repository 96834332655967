import React from 'react';

import { SyncOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { notification, Progress, Avatar, Col, Row } from 'antd';
import { wrap, proxy } from 'comlink';

import {
	orchestratorStatus as taskStatus,
	orchestratorStatusProps,
	ACCESS,
} from 'constants/constants';

import AppAvatar from 'components/AppAvatar';

export function usePollOrchestrator(finishTask) {
	const workerKey = (jobInfo) =>
		`active_poll_orchestrator_${jobInfo.companyID}`;

	const parseKey = (key) => {
		const meta = JSON.parse(localStorage.getItem(key));
		return { ...meta };
	};

	const startWorker = async ({ companyID, companyName }) => {
		const Poll = wrap(new Worker('/workers/pollworker.js'));
		const instance = new Poll({
			method: 'POST',
			url: `${process.env.REACT_APP_ORCHESTRATOR_URL}/logs/`,
			data: {
				company_id: companyID,
				operation: 'get_logs',
				app: 'logs',
			},
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Api-Key': process.env.REACT_APP_ORCHESTRATOR_KEY,
			},
		});

		(await instance).pollAPI(
			proxy((status, data) => {
				return callback(data.logs, { companyID, companyName });
			}),
			proxy((status, data) => {
				return callback(data.logs, { companyID, companyName });
			}),
			proxy(() => ({
				Authorization: `Bearer ${window.localStorage.getItem(ACCESS)}`,
			})),
		);
	};

	const onKickstart = ({ companyID, companyName }) => {
		openNotification(companyID, `Synchronizing menus for ${companyName}.`);
	};

	const callback = (data, { companyID, companyName }) => {
		const tasks = Object.keys(data)
			.filter((key) => !['main', 'migrate', 'mod'].includes(key))
			.map((key) => ({
				...data[key].lastrun,
				key,
			}));

		const completedTasks = tasks.filter(
			({ status }) => status === taskStatus.done || status === taskStatus.error,
		);

		if (tasks.length === completedTasks.length) {
			openNotification(
				companyID,
				`We have finished synchronizing your menus for ${companyName}.`,
				tasks,
				<CheckCircleOutlined />,
			);

			finishTask(workerKey({ companyID }));

			return true;
		} else {
			openNotification(
				companyID,
				`Synchronizing menus for ${companyName}.`,
				tasks,
			);
			return false;
		}
	};

	const renderIcon = (key) =>
		key === 'pos' ? (
			<Avatar className={'bg--primary'} size={'small'}>
				POS
			</Avatar>
		) : (
			<AppAvatar app={key} size={'small'} />
		);

	const openNotification = (
		companyID,
		message,
		tasks = [],
		icon = <SyncOutlined spin />,
	) => {
		notification['info']({
			key: workerKey({ companyID }),
			message,
			duration: 0,
			icon,
			placement: 'bottomLeft',
			description: (
				<div>
					{tasks.find(({ status }) => status === taskStatus.error) && (
						<div className="text--danger">
							We encountered an error while synchronizing your menus. Please
							contact customer support for assistance.
						</div>
					)}
					{tasks.map(({ status, key, merchantStatus }) => {
						const { strokeColor, status: progressStatus } =
							orchestratorStatusProps(status);
						return (
							<div key={key} style={{ marginTop: '5px' }}>
								<Row>
									<Col span={4}>{renderIcon(key)}</Col>
									<Col span={20}>
										<Progress
											strokeColor={{ '0%': strokeColor, '100%': strokeColor }}
											status={progressStatus}
											percent={100}
											showInfo={false}
											size="small"
										/>
									</Col>
								</Row>
								{status === taskStatus.error && (
									<div>
										{merchantStatus?.map((status) => (
											<div key={status}>{JSON.stringify(status)}</div>
										))}
									</div>
								)}
							</div>
						);
					})}
				</div>
			),
		});
	};

	return {
		parseKey,
		workerKey,
		onKickstart,
		startWorker,
	};
}
