import { createCoreAxiosInstance } from 'createAxiosInstance';

import { valueIsPresent, phoneIsPresent } from 'utils/helpers';
import { phoneNumberInvalid, emailInvalid } from 'utils/menuCheckout.helper';

export const isKioskContactInfoInvalid = ({ email, phone }) => {
	// both are missing
	if (!valueIsPresent(email) && !phoneIsPresent(phone)) {
		return true;
	}
	// email is present but invalid
	if (valueIsPresent(email) && emailInvalid(email)) {
		return true;
	}
	// phone is there but invalid
	if (phoneIsPresent(phone) && phoneNumberInvalid(phone)) {
		return true;
	}
	return false;
};

export const fetchRedirectUrl = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	const { data } = await axios.get(
		`api/v2/storefront/${companyID}/kiosk/store/name`,
	);
	return data.kiosk_store_name;
};

export const fetchTerminalReaders = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	const { data } = await axios.get(
		`api/v2/storefront/${companyID}/kiosk/terminal/reader`,
	);
	return data;
};

export const cancelTerminalPaymentIntent = async ({
	companyId,
	terminalId,
}) => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.post(
			'api/v2/transactions/checkout-sessions/cancel',
			{
				company_id: companyId,
				terminal_id: terminalId,
			},
		);
		return data;
	} catch (e) {
		console.error('Unable to cancel checkout session', e);
	}
};

export const fetchTerminalLocations = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.get(
			`api/v2/storefront/${companyID}/kiosk/terminal/location`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const createTerminalLocation = async (companyID, displayName) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.post(
			`api/v2/storefront/${companyID}/kiosk/terminal/location`,
			{ displayName },
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteTerminalLocation = async (companyID, locationID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.delete(
			`api/v2/storefront/${companyID}/kiosk/terminal/location?locationID=${locationID}`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const createTerminalReader = async (
	companyID,
	locationID,
	label,
	registrationCode,
) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.post(
			`api/v2/storefront/${companyID}/kiosk/terminal/reader`,
			{ locationID, registrationCode, label },
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteTerminalReader = async (companyID, readerID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.delete(
			`api/v2/storefront/${companyID}/kiosk/terminal/reader?readerID=${readerID}`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getStorefrontCacheImages = async (companyID) => {
	const axios = await createCoreAxiosInstance();
	try {
		const { data } = await axios.get(
			`api/v2/storefront/${companyID}/images/cache`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const formatAddressToHumanReadableString = (company) => {
	return `${company.address}, ${company.city}, ${company.state} ${company.zip_code}, ${company.country}`;
};

export const cacheImagesForKiosk = async (companyID, imageUrls) => {
	const cache = await caches.open(`${companyID}-assets`);
	imageUrls.forEach(async (image) => {
		try {
			await cache.add(image);
		} catch (error) {
			console.error(error);
		}
	});
};

export const downloadAssetsForKiosk = async (companyID) => {
	const { imageUrls } = await getStorefrontCacheImages(companyID);
	await cacheImagesForKiosk(companyID, imageUrls);
};
