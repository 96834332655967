import React from 'react';

import {
	ExceptionOutlined,
	SyncOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons';
import { notification, Progress } from 'antd';
import { wrap, proxy } from 'comlink';

import {
	orchestratorStatus as taskStatus,
	orchestratorStatusProps,
	ACCESS,
} from 'constants/constants';

export function usePollMigration(finishTask) {
	const workerKey = (jobInfo) => `active_poll_migration_${jobInfo.companyID}`;

	const parseKey = (key) => {
		const meta = JSON.parse(localStorage.getItem(key));
		return { ...meta };
	};

	const startWorker = async ({ companyID, companyName }) => {
		const Poll = wrap(new Worker('/workers/pollworker.js'));
		const instance = new Poll(
			{
				method: 'POST',
				url: `${process.env.REACT_APP_ORCHESTRATOR_URL}/logs/`,
				data: {
					company_id: companyID,
					operation: 'get_logs',
					app: 'logs',
				},
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-Api-Key': process.env.REACT_APP_ORCHESTRATOR_KEY,
				},
			},
			15000,
		);

		(await instance).pollAPI(
			proxy((status, data) => {
				return callback(data.logs, { companyID, companyName });
			}),
			proxy((status, data) => {
				return callback(data.logs, { companyID, companyName });
			}),
			proxy(() => ({
				Authorization: `Bearer ${window.localStorage.getItem(ACCESS)}`,
			})),
		);
	};

	const onKickstart = ({ companyID, companyName }) => {
		openNotification(
			companyID,
			`Publishing your menu mapping for ${companyName}.`,
		);
	};

	const callback = (data, { companyID, companyName }) => {
		const [task] = Object.keys(data)
			.filter((key) => ['migrate'].includes(key))
			.map((key) => ({
				...data[key].lastrun,
				key,
			}));

		if (task.status === taskStatus.done) {
			openNotification(
				companyID,
				`We have finished publishing your menu mapping for ${companyName}.`,
				task.status,
				<CheckCircleOutlined />,
			);
			finishTask(workerKey({ companyID }));

			return true;
		} else if (task.status === taskStatus.error) {
			openNotification(
				companyID,
				`We encountered a problem publishing your menu mapping for ${companyName}. Please contact support for assistance.`,
				task.status,
				<ExceptionOutlined />,
			);

			finishTask(workerKey({ companyID }));

			return true;
		} else {
			openNotification(
				companyID,
				`Publishing your menu mapping for ${companyName}.`,
				task.status,
			);
			return false;
		}
	};

	const openNotification = (
		companyID,
		message,
		status = 'RUNNING',
		icon = <SyncOutlined spin />,
	) => {
		const { strokeColor, status: progressStatus } =
			orchestratorStatusProps(status);
		notification['info']({
			key: workerKey({ companyID }),
			message,
			duration: 0,
			icon,
			description: (
				<Progress
					strokeColor={{ '0%': strokeColor, '100%': strokeColor }}
					status={progressStatus}
					percent={100}
					showInfo={false}
					size="small"
				/>
			),
		});
	};

	return {
		parseKey,
		workerKey,
		onKickstart,
		startWorker,
	};
}
