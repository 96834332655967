import {
	createCoreAxiosInstance,
	createMenuManagementAxiosInstance,
} from 'createAxiosInstance';

import {
	ADDRESS_FIELDS,
	REQUIRED_MERCHANT_FIELDS,
	REQUIRED_COMPANY_FIELDS,
	MERCHANT_STORE_PREP_TIME,
} from 'constants/storefront.constants';

export const updateMerchantStorefront = async ({ merchantID, data }) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.patch(`/storefront/${merchantID}/`, data);
	return response?.data;
};

export const updateCompany = async ({ companyID, data }) => {
	const axios = await createCoreAxiosInstance();
	const response = await axios.put(`api/v2/company/${companyID}`, data);
	return response?.data?.company;
};

export const formatAddress = (placeObject) => {
	const componentValues = ADDRESS_FIELDS.reduce(
		(previous, current) => ({ ...previous, [current]: null }),
		{},
	);

	placeObject.address_components.forEach((component) => {
		ADDRESS_FIELDS.forEach((field) => {
			if (component.types.includes(field)) {
				componentValues[field] = component.long_name;
			}
		});
	});

	const address = `${componentValues.street_number ?? ''} ${
		componentValues.route
	}`;

	return {
		address,
		address_latitude: placeObject.geometry.location.lat().toString(),
		address_longitude: placeObject.geometry.location.lng().toString(),
		city:
			componentValues.locality ||
			componentValues.sublocality ||
			componentValues.administrative_area_level_3,
		country: componentValues.country,
		state: componentValues.administrative_area_level_1,
		zip_code: componentValues.postal_code,
	};
};

export const getStorefrontInfoComplete = (fields, object) => {
	const missingFields = fields
		.filter((field) => {
			if (field === MERCHANT_STORE_PREP_TIME) {
				return !object[field] && object[field] !== 0;
			}
			return !object[field] || object[field] === 'None';
		})
		.map((fieldName) => fieldName.replaceAll('_', ' '));
	return [missingFields.length === 0, missingFields];
};

export const activeStorefronts = (merchants) => {
	return merchants
		.filter(({ merchant_store_active }) => merchant_store_active === true)
		.map(({ id }) => id);
};

export const disabledStorefronts = ({
	merchants,
	selectedCompany,
	storefrontConfig,
}) =>
	merchants
		.filter(
			(merchant) =>
				getStorefrontDisabled({ selectedCompany, merchant, storefrontConfig })
					.disabled,
		)
		.map(({ id }) => id);

export const getStorefrontDisabled = ({
	selectedCompany,
	merchant,
	storefrontConfig,
}) => {
	const stripeConnected = selectedCompany.stripe.isConnected;
	const [merchantInfoComplete, merchantMissingFields] =
		getStorefrontInfoComplete(REQUIRED_MERCHANT_FIELDS, merchant);

	const [companyInfoComplete, companyMissingFields] = getStorefrontInfoComplete(
		[...REQUIRED_COMPANY_FIELDS],
		selectedCompany,
	);

	const hasPublishedMenus =
		Object.keys(storefrontConfig?.merchants ?? {}).includes(
			merchant.id.toString(),
		) &&
		Object.values(storefrontConfig?.menus ?? {}).filter(
			(menu) => menu.merchant === merchant.id,
		).length > 0;

	const disabled =
		!stripeConnected ||
		!merchantInfoComplete ||
		!companyInfoComplete ||
		!hasPublishedMenus;

	return {
		stripeConnected,
		merchantInfoComplete,
		merchantMissingFields,
		companyInfoComplete,
		companyMissingFields,
		hasPublishedMenus,
		disabled,
	};
};

export const toggleMerchantsIsActive = async (merchants, status) => {
	let responses = [];
	for (const merchant of merchants) {
		try {
			responses.push(
				await updateMerchantStorefront({
					merchantID: merchant.id,
					data: {
						...merchant,
						merchant_store_active: status,
					},
				}),
			);
		} catch (e) {
			console.error(e);
		}
	}

	return responses;
};

export const labelIsMissing = (label) => !label || label?.length < 2;

export const descriptionIsMissing = (description) =>
	!description || description?.length < 2;

export const consumerGrandTotal = ({ grand_total, storefront_service_fee }) =>
	(parseFloat(grand_total) + parseFloat(storefront_service_fee)).toFixed(2);

export const isCheckboxValue = (itemValue) => Array.isArray(itemValue);

export const isRadioGroupValue = ({ parentUuidId, uuid, key }) =>
	`${uuid}_${parentUuidId}` === key;

export const isInputGroupValue = (itemValue) =>
	!isCheckboxValue(itemValue) && !isRadioGroupValue(itemValue);

export const getStripeReceiptUrl = async (orderId) => {
	const axios = await createCoreAxiosInstance();
	const { data } = await axios.get(
		`/api/v2/transactions/receipt/url/${orderId}`,
	);
	return data.receipt_url;
};

export const checkCompaniesForStorefront = async (companies) => {
	const axios = await createMenuManagementAxiosInstance();
	const { data } = await axios.get(
		`/api/storefronts/active?companies=${companies}`,
	);
	return data;
};

export const fetchStorefrontLocationsData = async (parentCompanyId) => {
	const axios = await createCoreAxiosInstance();
	const { data } = await axios.get(
		`api/v2/storefront/locations/${parentCompanyId}`,
	);
	return data;
};

export const normalizeStorefrontLocations = (locations) => {
	return locations.reduce((accumulator, currentStorefront) => {
		const formattedCityStateCountry = `${currentStorefront.address?.city}, ${currentStorefront.address?.state}, ${currentStorefront.address?.country}`;
		if (accumulator[formattedCityStateCountry]) {
			accumulator[formattedCityStateCountry].push(currentStorefront);
		} else {
			accumulator[formattedCityStateCountry] = [currentStorefront];
		}
		return accumulator;
	}, {});
};
