import React from 'react';

import ResponsiveLayoutContext from 'context/ResponsiveLayoutContext';
import PropTypes from 'prop-types';

import useProvideResponsiveLayout from 'hooks/useProvideResponsiveLayout';

export function ResponsiveLayoutProvider({ children }) {
	const responsiveLayout = useProvideResponsiveLayout();
	return (
		<ResponsiveLayoutContext.Provider value={responsiveLayout}>
			{children}
		</ResponsiveLayoutContext.Provider>
	);
}

ResponsiveLayoutProvider.propTypes = {
	children: PropTypes.node,
};

export default ResponsiveLayoutProvider;
