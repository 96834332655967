import React from 'react';

import NotificationContext from 'context/NotificationContext';
import PropTypes from 'prop-types';

import useProvideNotify from 'hooks/useProvideNotify';

export function NotificationProvider({ children }) {
	const notify = useProvideNotify();
	return (
		<NotificationContext.Provider value={notify}>
			{children}
		</NotificationContext.Provider>
	);
}

NotificationProvider.propTypes = {
	children: PropTypes.node,
};

export default NotificationProvider;
