import React from 'react';

import { Space, Upload, Button, notification } from 'antd';
import {
	createCoreAxiosInstance,
	createMenuManagementAxiosInstance,
} from 'createAxiosInstance';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import escapeRegExp from 'escape-string-regexp';
import { PhoneNumberUtil } from 'google-libphonenumber';
import PropTypes from 'prop-types';
import { Upload as RFUpload } from 'react-feather';

import {
	DEFAULT_TRUNCATE_LIMIT,
	IMAGE_CONTENT_REQUEST_TYPE,
	MILES_TO_KILOMETERS_MULTIPLIER,
	KILOMETERS_TO_MILES_MULTIPLIER,
} from 'constants/constants';

import config from 'config/app.config';

dayjs.extend(utc);
dayjs.extend(timezone);

export const timeZoneOffset = (timezone, date) => {
	return dayjs(date).tz(timezone).utcOffset();
};

/** Helper method for case insensitive substrings */
export const isSubstring = (haystack, needle) => {
	const searchPattern = new RegExp(escapeRegExp(needle), 'i');
	if (Array.isArray(haystack)) {
		const mappedHaystacks = haystack.filter((currentStack) =>
			currentStack.match(searchPattern),
		);
		return mappedHaystacks.length > 0;
	}

	return haystack?.match(searchPattern);
};

/** Helper method to make array unique */
export const normalizeArray = (array) =>
	Array.from(new Set(array)).filter((value) => value !== undefined);

/** Helper method to chunk an array into chunkSized sub arrays */
export const chunkArray = (array, chunkSize) =>
	array.reduce((accumulator, modifier, index) => {
		const chunk = Math.floor(index / chunkSize);
		accumulator[chunk] = [].concat(accumulator[chunk] || [], modifier);
		return accumulator;
	}, []);

export function formatCurrency(value) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return formatter.format(value);
}

/** Helper method to ensure an integration task is finished - based on its stage */
// taskStage === 1 means its in the middle of a multilocation task
export function integrationTaskIsFinished(taskStage) {
	const taskFinished = taskStage === '0' || taskStage === '2';
	if (taskFinished) {
		return true;
	} else {
		return false;
	}
}

/**
 * Map a configuration object to an enum from the API
 * @param {string} app: An integration enum from the backend
 * @returns {object}: A configuration object
 */
export const getConfigFromIntegration = (integration) => {
	switch (integration) {
		case 'allsetdev':
			return config.integrations.allSetDev;
		case 'allset':
		case 'ALLSET':
			return config.integrations.allSet;
		case 'chownow':
		case 'CHOWNOW':
		case 'chowNow':
			return config.integrations.chowNow;
		case 'caviar':
		case 'CAVIAR':
			return config.integrations.caviar;
		case 'doordash':
		case 'DOORDASH':
		case 'doorDash':
			return config.integrations.doorDash;
		case 'eatstreet':
		case 'EATSTREET':
		case 'eatStreet':
			return config.integrations.eatStreet;
		case 'ezcater':
		case 'EZCATER':
		case 'ezCater':
			return config.integrations.ezCater;
		case 'freshbytes':
		case 'FRESHBYTES':
			return config.integrations.freshBytes;
		case 'grubhub':
		case 'GRUBHUB':
		case 'grubHub':
			return config.integrations.grubHub;
		case 'lunchbox':
			return config.integrations.lunchBox;
		case 'lunchboxdev':
			return config.integrations.lunchBoxDev;
		case 'mixbowl':
		case 'MIXBOWL':
		case 'mixBowl':
			return config.integrations.mixbowl;
		case 'ritual':
		case 'RITUAL':
			return config.integrations.ritual;
		case 'skip':
		case 'SKIP':
		case 'skipTheDishes':
			return config.integrations.skip;
		case 'sociavore':
			return config.integrations.sociavore;
		case 'sociavoredev':
			return config.integrations.sociavoreDev;
		case 'MENUU':
		case 'menuu':
			return config.integrations.menuu;
		case 'menuudev':
			return config.integrations.menuuDev;
		case 'uber':
		case 'UBEREATS':
		case 'uberEats':
		case 'ubereats':
			return config.integrations.uberEats;
		default:
			return config.integrations.default;
	}
};

export function getAllMerchants(companies) {
	const merchants = companies?.map((company) => {
		return company.merchants?.map((merchant) => {
			return {
				name: merchant.name,
				location: company.name,
			};
		});
	});

	return merchants.flat();
}

/**
 * Check for subset array
 *
 * @param {[]} main: Array of elements
 * @param {[]} subarray: Array of elements
 * @returns {boolean} True if each element of subarray is in main
 */
export const hasSubArray = (main, subarray) => {
	return subarray.every((v) => {
		return main.includes(v);
	});
};
export const atLeastOneNumber = (value) => !!value?.match(/(?=.*\d)/);
export const atLeastOneLowerCase = (value) => !!value?.match(/.*[a-z].*/);
export const atleastOneUpperCase = (value) => !!value?.match(/.*[A-Z].*/);
export const noEmptySpaces = (value) => value && !value?.match(/(?=.*\s)/);
export const atLeastOneSpecialCharacter = (value) =>
	!!value?.match(/(?=.*[!#$%&+-=?@^_~])/);
export const atLeastTenCharactersLong = (value) => !!value?.match(/.{10,}/);

export const toTitleCase = (word) =>
	`${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`;

export const truncateString = (string, limit = DEFAULT_TRUNCATE_LIMIT) => {
	if (string.length > limit) {
		return string.substring(0, limit) + '...';
	} else {
		return string;
	}
};

export const getKeyByValue = (object, value) => {
	return Object.keys(object ?? {}).find((key) => object?.[key] === value);
};

export const formatScheduleTime = (time) => {
	const [hour, minute] = time.split(':');
	const hourInt = parseInt(hour) % 24;

	// 0:30 -> 12:30 AM
	if (hourInt === 0) {
		return `12:${minute} AM`;
	}

	// 07:30 -> 7:30 AM
	if (hourInt < 12) {
		return `${hourInt}:${minute} AM`;
	}

	// 12:30 -> 12:30 PM
	if (hourInt === 12) {
		return `${hourInt}:${minute} PM`;
	}

	// 14:30 -> 2:30:pm
	return `${hourInt - 12}:${minute} PM`;
};

export const uploadImage = async ({ companyId, uploadImageFile, itemType }) => {
	const axios = await createMenuManagementAxiosInstance(
		IMAGE_CONTENT_REQUEST_TYPE,
	);
	let data = new FormData();
	data.append('file', uploadImageFile.originFileObj);
	data.append('name', uploadImageFile.name);
	data.append('type', itemType);
	const response = await axios.post(`/company/${companyId}/files`, data);
	return response?.data;
};

export const renderImageUpload = ({
	record,
	setUploadImageFile,
	defaultImageUrl = null,
	showRemoveIcon = true,
	setImageError = () => {},
	isSourceChowNow = false,
}) => {
	const handleChange = (info) => {
		setImageError(null);
		setUploadImageFile(null);

		if (info.fileList[0]) {
			const file = {
				name: record?.uuid,
				originFileObj: info.fileList[0].originFileObj,
			};
			setUploadImageFile(file);
		}
	};

	const defaultImage = [
		{
			uid: '1',
			status: 'done',
			url: defaultImageUrl,
		},
	];

	return (
		<Upload
			accept=".jpeg,.jpg,.png"
			listType="picture"
			maxCount={1}
			showUploadList={{ showRemoveIcon }}
			onChange={(file) => {
				handleChange(file);
			}}
			name={record?.uuid}
			customRequest={({ onSuccess }) => {
				onSuccess();
			}}
			defaultFileList={defaultImageUrl && defaultImage}
			className="upload-list-inline"
		>
			<Button className={isSourceChowNow ? 'full-width' : ''}>
				<Space direction="horizontal">
					{!isSourceChowNow && <RFUpload size={16} />} Upload an Image
				</Space>
			</Button>
		</Upload>
	);
};

export const convertStringToCypressDataCy = (string = ' ') => {
	return string.trim().replace(/ /g, '-').toLowerCase();
};

export const capitalizeWords = (string) => {
	return string.replace(/(?:^|\s)\S/g, (a) => {
		return a.toUpperCase();
	});
};

export const valueIsPresent = (value) => {
	return value && !['Undefined', 'Undefiend', '', 'None'].includes(value);
};

export const phoneIsPresent = (phone) => {
	return phone && phone.length > 2 && !['Undefined'].includes(phone);
};

// Proptypes
renderImageUpload.propTypes = {
	record: PropTypes.object.isRequired,
	setUploadImageFile: PropTypes.func.isRequired,
	defaultImageUrl: PropTypes.string,
	showRemoveIcon: PropTypes.bool,
	setImageError: PropTypes.func,
};

uploadImage.propTypes = {
	companyId: PropTypes.number.isRequired,
	uploadImageFile: PropTypes.object.isRequired,
	itemType: PropTypes.string.isRequired,
};

/**
 * Parse a phone number for the antd phone number input initial state
 *
 * @example
 * '+1 250 555 5555' -> { short: 'CA', code: '1' phone: '5555555', isValid: true  }
 *
 * @param {string} phoneNumber: A phone number string '+1 250 555 5555'
 * @returns An object splitting the phone number into its parts
 */
export const parsePhoneNumber = (phoneNumber) => {
	const phoneNumberUtil = PhoneNumberUtil.getInstance();
	try {
		const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber);
		const nationalNumber = parsedPhoneNumber.getNationalNumber().toString();
		const areaCode = nationalNumber.substring(0, 3) || '';
		const telephonePrefix = nationalNumber.substring(3, 6) || '';
		const lineNumber = nationalNumber.substring(6, 10) || '';
		return {
			short: phoneNumberUtil.getRegionCodeForNumber(parsedPhoneNumber),
			phone: `${areaCode}-${telephonePrefix}-${lineNumber}`,
			code: parsedPhoneNumber.getCountryCode(),
			isValid: phoneNumberUtil.isValidNumber(parsedPhoneNumber),
		};
	} catch (e) {
		return {
			short: 'CA',
			phone: phoneNumber,
			code: 1,
			isValid: false,
		};
	}
};

/**
 * Format a phone number for user display
 *
 * @param {string} phoneNumber: A phone number string +12505556666
 * @returns A formatted phone number string +X YYY-ZZZ-ZZZZ
 */
export const formatPhoneNumber = (phoneNumberString) => {
	const parsedPhoneNumber = parsePhoneNumber(phoneNumberString);
	return `+${parsedPhoneNumber.code} ${parsedPhoneNumber.phone}`;
};

/**
 * Take a phone number object from antd phone input and put to a string
 *
 * @example
 * `{ code: 1, phone: '(800) 900 7000' }` -> '+18009007000`
 *
 * @param {object} phoneNumberObject: object with code and phone number
 * @returns {string} Valid phone number string in E.164 format
 */
export const phoneNumberToString = ({ code, phone }) =>
	`+${code || ''}${phone?.replace(/[\D]/g, '') || ''}`;

export const formatExpirationDate = ({ month, year }) => {
	return `${month < 10 ? `0${month}` : month}/${year % 100}`;
};

export const logToCore = async ({ level, message, alert = false }) => {
	const axios = await createCoreAxiosInstance();
	const extra = alert ? { alert: true } : null;
	await axios.post('/logging', {
		level,
		message,
		extra,
	});
};

export const convertMilesToKilometers = (miles) =>
	Math.round(Math.abs(miles) * MILES_TO_KILOMETERS_MULTIPLIER);

export const convertKilometersToMiles = (kilometers) =>
	Math.round(Math.abs(kilometers) * KILOMETERS_TO_MILES_MULTIPLIER);

export const updateUserMeta = async ({
	userMeta,
	failureMessage = 'Unable to update your preferences. Please try again later.',
}) => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.patch('api/v2/user-meta', {
			user_meta: userMeta,
		});
		return data;
	} catch (error) {
		notification.info({
			key: 'update-meta-failure-notice',
			message: failureMessage,
			duration: 3,
		});
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateCompanyMeta = async ({
	company,
	meta,
	failureMessage = 'Unable to update. Please try again later.',
}) => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.patch(
			`api/v2/company-meta/${company}`,
			{
				company_meta: meta,
			},
		);
		return data;
	} catch (error) {
		notification.info({
			key: 'update-meta-failure-notice',
			message: failureMessage,
			duration: 3,
		});
		console.error(error);
		return Promise.reject(error);
	}
};

export const updateMerchantMeta = async ({
	merchant,
	meta,
	failureMessage = 'Unable to update. Please try again later.',
}) => {
	try {
		const axiosInstance = await createCoreAxiosInstance();
		const { data } = await axiosInstance.patch(
			`api/v2/merchant-meta/${merchant}`,
			{
				merchant_meta: meta,
			},
		);
		return data;
	} catch (error) {
		notification.info({
			key: 'update-meta-failure-notice',
			message: failureMessage,
			duration: 3,
		});
		console.error(error);
		return Promise.reject(error);
	}
};

export async function requestChunker(axios, endpoint, parameterArray) {
	const maxChunkSize = 200;
	const allowedConcurrentRequests = 5;
	const parameterChunks = [];
	// split the parameter array into manageable arrays
	for (let i = 0; i < parameterArray.length; i += maxChunkSize) {
		const chunk = parameterArray.slice(i, i + maxChunkSize);
		parameterChunks.push(chunk);
	}
	// for each array of parameters, make the request
	let allData = [];
	while (parameterChunks.length) {
		const currentBatchReturn = await Promise.all(
			parameterChunks
				.splice(0, allowedConcurrentRequests) // perform the queries in batches to avoid throttling failures
				.map((chunk) => axios.get(`${endpoint}${chunk}`)),
		);
		allData = [...allData, ...currentBatchReturn];
	}

	// flatten the data under one object and return in a familiar structure
	return {
		data: allData.reduce((accumulator, { data }) => {
			// check the data return type
			if (Array.isArray(data) && !Array.isArray(accumulator)) {
				return [...data];
			} else if (Array.isArray(data) && Array.isArray(accumulator)) {
				return [...accumulator, ...data];
			} else {
				// loop over each object key to prevent overwriting data
				Object.keys(data).forEach((key) => {
					if (!accumulator[key]) {
						accumulator[key] = data[key];
					} else if (Array.isArray(accumulator[key])) {
						accumulator[key] = [...accumulator[key], ...data[key]];
					} else {
						accumulator[key] = { ...accumulator[key], ...data[key] };
					}
				}, {});
			}

			return accumulator;
		}, {}),
	};
}
