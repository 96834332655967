import React from 'react';

import { Avatar, Popover } from 'antd';
import PropTypes from 'prop-types';

import { APP_ICON_BASE_URL } from 'constants/reporting.constants.js';

AppAvatar.propTypes = {
	app: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	popoverContent: PropTypes.object,
	onHover: PropTypes.bool,
};

export default function AppAvatar({
	app,
	className,
	popoverContent = null,
	size = 'default',
	onHover = false,
}) {
	if (popoverContent) {
		return (
			<Popover content={popoverContent} trigger={onHover ? 'hover' : 'click'}>
				<Avatar
					className={`app-avatar ${className}`}
					key={app}
					shape="circle"
					size={size}
					src={`${APP_ICON_BASE_URL}/${app
						?.replace(' ', '')
						.toLowerCase()}.png`}
				/>
			</Popover>
		);
	}
	return (
		<Avatar
			className={`app-avatar ${className}`}
			key={app}
			shape="circle"
			size={size}
			src={`${APP_ICON_BASE_URL}/${app?.replace(' ', '').toLowerCase()}.png`}
		/>
	);
}
